import { useState } from 'react';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

function Layout({ children }) {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div className="lg:flex h-screen">
      <Sidebar showSideBar={showSideBar} />
      <main className="lg:flex-1 flex flex-col bg-[#F9FAFB]">
        <Topbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
        <div onClick={() => setShowSideBar(false)} className="lg:flex-1 lg:overflow-y-scroll overflow-auto scroll-none py-8 lg:p-8">
          <div className="container">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Layout;
