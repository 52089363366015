/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import setAuthToken from '../../../actions/setAuthToken';
import {
  Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue,
} from '../../../components/ui/select';
import Config from '../../../config';
import MeetingCalendarWrapper from '../components/meeting-calendar-wrapper';
import MeetingStats from '../components/meeting-stats';
import ProjectSpaceIntro from '../components/project-space-intro';
import UpcomingMeetings from '../components/upcoming-meetings';
import UpcomingMeetingsDefault from '../components/upcoming-meetings-default';
import WelcomeHeading from '../components/welcome-heading';
import Layout from '../layout';

function DashboardIndex() {
  const [user, setUser] = useGlobal('user');
  const [meetingStats, setMeetingStats] = useState(null);
  const [teamSpaces] = useGlobal('teamSpaces');
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const isDefaultWorkspace = user?.workspace?.default;

  const setToken = useGlobal('token')[1];
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    document.cookie = 'jwt_token=; path=/; domain=.saybriefly.com; secure; SameSite=None;';
  };

  useEffect(() => {
    if (user?.isGuest && !location.pathname.includes('/meeting')) {
      // handleLogout();
      navigate('/not-allowed');
    }
  }, [user]);

  useEffect(() => {
    axios
      .get(`${Config.url || ''}/api/v1/user/dashboard-meeting-stats`)
      .then((data) => {
        setMeetingStats(data?.data?.stats);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(selectedWorkspace);

  return (
    <Layout>
      <div className="space-y-6 flex flex-col">
        {
          !isDefaultWorkspace && (
            <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">
              <div className="flex flex-col gap-8">
                <WelcomeHeading />
                <div className="space-y-6">
                  {/* <MeetingHistory /> */}
                  <MeetingStats data={meetingStats} />
                  {!isDefaultWorkspace && <ProjectSpaceIntro />}
                </div>
              </div>
              <div>
                {
                  isDefaultWorkspace ? <UpcomingMeetingsDefault /> : <UpcomingMeetings />
                }
              </div>
            </div>
          )
        }
        {
          isDefaultWorkspace && (
            <>
              <div className="flex items-center justify-between">
                <WelcomeHeading />

                <div className="flex gap-6 items-center">
                  <Select onValueChange={(e) => setSelectedWorkspace(e)}>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select Workspace" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Workspaces</SelectLabel>
                        {
                          teamSpaces?.map((item) => (
                            <SelectItem key={item._id} value={item._id}>
                              {item.name}
                              {' '}
                              {item.default && '(primary)'}
                            </SelectItem>
                          ))
                        }
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <MeetingCalendarWrapper selectedWorkspace={selectedWorkspace} />
            </>
          )
        }
      </div>
    </Layout>
  );
}

export default DashboardIndex;
