import { useGlobal } from 'reactn';
import { useNavigate } from 'react-router-dom';
import setAuthToken from '../../../actions/setAuthToken';

function NotAllowedPage() {
  const setToken = useGlobal('token')[1];
  const [setUser] = useGlobal('user');

  const navigate = useNavigate();
  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    document.cookie = 'jwt_token=; path=/; domain=.saybriefly.com; secure; SameSite=None;';
  };
  const handleLoginPage = () => {
    handleLogout();
    navigate('/login');
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-red-600">Access Denied</h1>
      <p className="mt-4 text-gray-600">You do not have permission to view this page.</p>
      <a
        onClick={handleLoginPage}
        className="mt-6 px-4 py-2 text-center text-sm py-2 px-4 bg-[#173300] text-[#ffed74] hover:text-[#ffed74] rounded-lg"
      >
        Go to Login
      </a>
    </div>
  );
}

export default NotAllowedPage;
