/* eslint-disable no-case-declarations */
import Actions from '../constants/Actions';

const initialState = {
  io: null,
  room: null,
  messages: [],
  rooms: [],
  id: null,
  onlineUsers: [],
  refreshMeetings: null,
  notifications: [],
  notificationCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case Actions.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.count,
      };
    case Actions.IO_INIT:
      return {
        ...state,
        io: action.io,
        id: action.io.id,
      };
    case Actions.SET_ROOMS:
      return {
        ...state,
        rooms: action.rooms,
      };
    case Actions.SET_ROOM:
      return {
        ...state,
        room: action.room,
      };
    case Actions.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case Actions.MORE_MESSAGES:
      return {
        ...state,
        messages: [...action.messages, ...state.messages],
      };
    case Actions.MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
    case Actions.ONLINE_USERS:
      return {
        ...state,
        onlineUsers: action.data,
      };
    case Actions.REFRESH_MEETINGS:
      return {
        ...state,
        refreshMeetings: action.timestamp,
      };
    case Actions.SET_ROOM_CAPTIONS:

      const captions = state?.room?.captions?.length > 0 ? [...state.room.captions] : [];
      const index = captions.findIndex((caption) => caption.user?._id?.toString() === action.data.user?._id?.toString());
      if (index !== -1) {
        captions[index] = {
          ...action.data,
        };
      } else {
        captions.push(action.data);
      }

      return {
        ...state,
        room: {
          ...state.room,
          captions,
        },
      };
    default:
      return state;
  }
};

export default reducer;
