/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import getContacts from '../../../actions/getContacts';
import AddContactButton from '../components/add-contact-button';
import ContactDetails from '../components/contact-details';
import ContactListing from '../components/contact-listing';
import PageHeading from '../components/page-heading';
import Layout from '../layout';

function ContactPage() {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const { data } = await getContacts(page);
      if (data?.success) {
        setContacts(data?.data?.contacts);
        setSelectedContact(data?.data?.contacts[0]);
        setRefetch(false);
      }
    };
    getData();
  }, [page, refetch]);

  return (
    <Layout>
      <div className="grid lg:grid-cols-5 grid-cols-1 gap-6 w-full">
        <div className="lg:col-span-2 py-6 flex flex-col items-start gap-6">
          <PageHeading title="Contact" />
          <AddContactButton setRefetch={setRefetch} />

          <div className="w-full px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 justify-start items-center gap-2 inline-flex">
            <div className="items-center gap-2 flex w-full">
              <img src="/image/icons/search-lg.svg" alt="" className="w-5 h-5 relative" />
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="text-gray-500 focus:border-none focus:outline-none w-full text-base font-normal leading-normal"
                placeholder="Search"
              />
            </div>
          </div>

          <ContactListing
            contacts={contacts}
            setPage={setPage}
            page={page}
            search={search}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
          />
        </div>
        {
          selectedContact && (
            <div className="lg:col-span-3 bg-gray-100 rounded-lg p-6">
              <ContactDetails selectedContact={selectedContact} setRefetch={setRefetch} />
            </div>
          )
        }
      </div>
    </Layout>
  );
}

export default ContactPage;
