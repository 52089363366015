/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import * as mediasoup from 'mediasoup-client';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getGlobal, useGlobal } from 'reactn';
import { toast } from 'sonner';
import postClose from '../../actions/postClose';
import setAuthToken from '../../actions/setAuthToken';
import config from '../../config';
import Actions from '../../constants/Actions';
import './Meeting.sass';
import AddPeers from './components/AddPeers';
import Join from './components/Join';
import MeetingBottomRight from './components/MeetingBottomRight';
import MeetingPeople from './components/MeetingPeople';
import Ringing from './components/Ringing';
import Streams from './components/Streams';
import TopBar from './components/TopBar';
import TopBarTransparent from './components/TopBarTransparent';
import MeetingBottomLeft from './components/MeetingBottomLeft';
import AudioButton from './components/AudioButton';
import VideoButton from './components/VideoButton';
import SettingsButton from './components/SettingsButton';
import EmojiButton from './components/EmojiButton';
import InviteButton from './components/InviteButton';
import getMeetingByCode from '../../actions/getMeetingByCode';

let transport;
let videoProducer;
let screenProducer;
let audioProducer;

function Meeting() {
  const peers = useSelector((state) => state.rtc.peers);
  const [device, setDevice] = useState(null);
  const io = useSelector((state) => state.io.io);
  const producers = useSelector((state) => state.rtc.producers);
  const lastLeave = useSelector((state) => state.rtc.lastLeave);
  const lastLeaveType = useSelector((state) => state.rtc.lastLeaveType);
  const increment = useSelector((state) => state.rtc.increment);
  const closingState = useSelector((state) => state.rtc.closingState);
  const [streams, setStreams] = useGlobal('streams');
  const [localStream, setLocalStream] = useGlobal('localStream');
  // const [localStreamObject, setLocalStreamObject] = useGlobal('localStreamObject');
  const [video, setVideo] = useGlobal('video');
  const [audio, setAudio] = useGlobal('audio');
  const [audioDevices, setAudioDevices] = useGlobal('audioDevices');
  const [videoDevices, setVideoDevices] = useGlobal('videoDevices');
  const [videoSelectListShow, setVideoSelectListShow] = useState(false);
  const [audioSelectListShow, setAudioSelectListShow] = useState(false);
  const [videoDeviceId, setVideoDeviceId] = useState(false);
  const [audioDeviceId, setAudioDeviceId] = useState(false);
  const [isScreen, setScreen] = useGlobal('screen');
  const [audioStream, setAudioStream] = useGlobal('audioStream');
  const [videoStream, setVideoStream] = useGlobal('videoStream');
  const [audioProccessorObject, setAudioProcessor] = useGlobal('audioProccessorObject');
  const [mediaStreamSourceObject, setMediaStreamSource] = useGlobal('mediaStreamSourceObject');
  const [audioContextObject, setAudioContext] = useGlobal('audioContextObject');
  const setCurrentMessageLength = useGlobal('currentMessageLength')[1];
  const [screenStream, setScreenStream] = useGlobal('screenStream');
  const [callStatus, setCallStatus] = useGlobal('callStatus');
  const callDirection = useGlobal('callDirection')[0];
  const [joined, setJoined] = useGlobal('joined');
  const [isMaximized, setMaximized] = useState(true);
  const [isGrid, setGrid] = useState(true);
  const [topBar, setTopBar] = useState(true);
  const [acccepted, setAccepted] = useGlobal('accepted');
  const [showPanel, setShowPanel] = useGlobal('showPanel');
  const setOver = useGlobal('over')[1];
  const setMeetingID = useGlobal('meetingID')[1];
  const [meeting, setMeeting] = useGlobal('meeting');
  const [addPeers, setAddPeers] = useState(false);
  const counterpart = useSelector((state) => state.rtc.counterpart) || {};
  const [user, setUser] = useGlobal('user');
  const setToken = useGlobal('token')[1];
  const [showCaption, setShowCaption] = useGlobal('showCaption');

  const [chatRoomId, setChatRoomId] = useState(null);
  const [chatRoomLoading, setChatRoomLoading] = useState(false);
  const [agenda, setAgenda] = useState([]);
  const [timeOutArray, setTimeOutArray] = useState([]);

  const answerIncrement = useSelector((state) => state.rtc.answerIncrement);
  const answerData = useSelector((state) => state.rtc.answerData);

  const [whisperSocket, setWhisperSocket] = useState(null);
  // const [isServerReady, setIsServerReady] = useState(false);

  const params = useParams();
  const meetingCode = params?.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCurrentPeerApproved = peers?.[Object.keys(peers)?.filter((peerKey) => peers[peerKey]?.userID === user?._id)?.[0]]?.approveJoin;

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    // remove token from cookie
    document.cookie = 'jwt_token=; path=/; domain=.saybriefly.com; secure; SameSite=None;';
  };

  useEffect(() => {
    if (!answerData) return;
    if (callDirection === 'outgoing' && callStatus !== 'in-call' && answerData.meetingID === meeting?.meeting?._id) {
      setJoined(true);
      init();
    }
  }, [answerIncrement, answerData]);

  useEffect(() => {
    const getData = async () => {
      if (!meetingCode) return;
      try {
        setChatRoomLoading(true);
        const { data: res } = await getMeetingByCode(meetingCode);
        // console.log(res?.data?.booking?.formSubmission);
        setAgenda(res?.data?.booking?.formSubmission);
        if (res) {
          if (res.data?.meeting?.room) {
            setChatRoomId(res.data?.meeting?.room);
          }
          setMeeting(res.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setChatRoomLoading(false);
      }
    };
    // if (isChatOpen && !chatRoomId) {
    getData();
    // }
  }, [meetingCode]);

  useEffect(() => {
    if (acccepted) {
      setAccepted(false).then(() => {
        setJoined(true);
        init();
      });
    }
  }, [acccepted]);

  useEffect(() => {
    setMeetingID(meeting?.meeting?._id);
    return () => {
      if (getGlobal()?.callStatus !== 'in-call') {
        try {
          if (getGlobal()?.audioStream) {
            getGlobal()
              ?.audioStream?.getTracks()
              ?.forEach((track) => track?.stop());
          }
        } catch (e) { }
        try {
          if (getGlobal().videoStream) {
            getGlobal()
              ?.videoStream?.getTracks()
              ?.forEach((track) => track.stop());
          }
        } catch (e) { }
      }
    };
  }, []);

  const getAudio = async () => {
    try {
      let stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
      if (audioDeviceId) {
        stream = await navigator?.mediaDevices?.getUserMedia({ audio: { deviceId: audioDeviceId } });
      }
      setAudioStream(stream);
      return stream;
    } catch (err) {
      setAudio(false);
      console.log('Something went wrong with Audio stream: ', err);
      return null;
    }
  };
  const getVideo = async () => {
    try {
      let stream = navigator?.mediaDevices?.getUserMedia({ video: true });
      if (videoDeviceId) {
        stream = await navigator?.mediaDevices?.getUserMedia({ video: { deviceId: videoDeviceId } });
      }
      await setVideoStream(stream);
      return stream;
    } catch (err) {
      setVideo(false);
      console.log('Something went wrong with Video stream: ', err);
      return null;
    }
  };

  const getScreen = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });

      // Add an event listener to handle when the screen sharing is stopped
      const track = stream.getVideoTracks()[0]; // Get the video track
      console.log(stream.getVideoTracks(), 'track');
      track.onended = async () => {
        console.log('Screen sharing has been stopped by the user.');
        // Add any cleanup or fallback behavior here
        await io.request('remove', { producerID: screenProducer.id, roomID: meeting?.meeting?._id });
        screenProducer.close();
        screenProducer = null;
        await setScreen(false);

        const videoStreamObject = await videoStream;

        console.log(videoStreamObject, 'videoStreamObject');

        if (videoStreamObject?.active) {
          await setLocalStream(videoStreamObject);
        } else {
          stopScreen();
          setLocalStream(null);
        }
      };
      setScreenStream(stream);
      return stream;
    } catch (err) {
      console.log('Something went wrong with Screen stream: ', err);
      return null;
    }
  };

  function resampleTo16kHz(audioData, origSampleRate = 44100) {
    const data = new Float32Array(audioData);
    const targetLength = Math.round(data.length * (16000 / origSampleRate));
    const resampledData = new Float32Array(targetLength);
    const springFactor = (data.length - 1) / (targetLength - 1);
    // eslint-disable-next-line prefer-destructuring
    resampledData[0] = data[0];
    resampledData[targetLength - 1] = data[data.length - 1];

    for (let i = 1; i < targetLength - 1; i++) {
      const index = i * springFactor;
      const leftIndex = Math.floor(index);
      const rightIndex = Math.ceil(index);
      const fraction = index - leftIndex;
      resampledData[i] = data[leftIndex] + (data[rightIndex] - data[leftIndex]) * fraction;
    }

    return resampledData;
  }

  function generateUUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  const produceAudio = async (stream) => {
    try {
      const useStream = stream || audioStream;

      if (!useStream || !useStream.getAudioTracks().length) {
        console.error('No valid audio stream available.');
        return null;
      }

      setAudio(true);
      try {
        const track = useStream.getAudioTracks()[0];
        const params = { track };
        audioProducer = await transport.produce(params);
      } catch (err) {
        console.log('Failed to produce audio:', err);
        setAudio(false);
        return null;
      }

      // const audioDataCache = [];
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const mediaStreamSource = audioContext.createMediaStreamSource(useStream);
      const processor = audioContext.createScriptProcessor(4096, 1, 1);

      // processor.onaudioprocess = (event) => {
      //   console.log('audio process 1', whisperSocket);
      //   if (!audioContext || !whisperSocket) return;

      //   const inputData = event.inputBuffer.getChannelData(0);
      //   const audioData16kHz = resampleTo16kHz(inputData, audioContext.sampleRate);

      //   // const avgVolume = inputData.reduce((sum, val) => sum + Math.abs(val), 0) / inputData.length;
      //   // const silenceThreshold = 0.02; // Experiment with this threshold

      //   if (whisperSocket.readyState === 1) {
      //     whisperSocket.send(audioData16kHz);
      //   }
      // };

      mediaStreamSource.connect(processor);
      processor.connect(audioContext.destination);

      setAudioProcessor(processor);
      setMediaStreamSource(mediaStreamSource);
      setAudioContext(audioContext);
    } catch (error) {
      console.log(error, 'produceAudio error');
    }
  };

  const produceVideo = async (stream) => {
    try {
      const useStream = stream || videoStream;
      setVideo(true);
      try {
        const track = useStream.getVideoTracks()[0];
        const params = { track, appData: { isScreen: false } };
        await setLocalStream(useStream);
        // if (useStream) {
        //   await setLocalStreamObject({
        //     ...localStreamObject,
        //     video: useStream,
        //   });
        // }
        videoProducer = await transport.produce(params);
      } catch (err) {
        console.log('get user media produce failed: video ', err);
        setVideo(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const produceScreen = async (stream) => {
    try {
      const track = stream.getVideoTracks()[0];
      const params = { track, appData: { isScreen: true } };
      await setLocalStream(stream);
      // if (stream) {
      //   await setLocalStreamObject({
      //     ...localStreamObject,
      //     screen: stream,
      //   });
      // }
      screenProducer = await transport.produce(params);
      await setScreen(true);
    } catch (err) {
      console.log('get user media produce failed: screen ', err);
    }
  };

  const stopScreen = async () => {
    try {
      if (localStream) localStream.getVideoTracks()[0].stop();
      await io.request('remove', { producerID: screenProducer.id, roomID: meeting?.meeting?._id });
      screenProducer.close();
      screenProducer = null;
      await setScreen(false);

      const videoStreamObject = await videoStream;
      console.log(videoStreamObject, 'videoStreamObject');
      if (videoStreamObject?.active) {
        await setLocalStream(videoStreamObject);
      } else {
        setLocalStream(null);
      }

      // if (video && localStreamObject?.video) {
      //   // console.log('stop screen', localStreamObject);
      //   await setLocalStream(localStreamObject?.video);
      //   localStreamObject?.screen?.stop();
      // }
    } catch (e) {
      console.log(e);
    }
  };

  const stopVideo = async () => {
    try {
      if (localStream) localStream?.getVideoTracks()[0]?.stop();
      setLocalStream(null);

      await io.request('remove', { producerID: videoProducer.id, roomID: meeting?.meeting?._id });
      videoProducer.close();
      videoProducer = null;
      await setVideo(false);

      const screenVideoStreamObject = await screenStream;
      if (screenVideoStreamObject?.active) {
        await setLocalStream(screenVideoStreamObject);
      } else {
        setLocalStream(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const stopAudio = async () => {
    try {
      await io?.request('remove', { producerID: audioProducer.id, roomID: meeting?.meeting?._id });
      audioProducer?.close();
      audioProducer = null;
      // if (whisperSocket) whisperSocket.close();

      if (audioContextObject.state === 'running') {
        audioProccessorObject.disconnect();
        mediaStreamSourceObject.disconnect();
      } else {
        console.error('Audio context is inactive.');
      }

      await setAudio(false);
      setAudioStream(null);
    } catch (e) {
      console.log(e);
    }
  };

  const switchAudioDevice = async (newAudioDeviceId) => {
    setAudioDeviceId(newAudioDeviceId);
    try {
      if (audioStream?.active) {
        stopAudio();
        const newStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: newAudioDeviceId } });
        await produceAudio(newStream);
        setAudioStream(newStream);
      }
    } catch (error) {
      console.error('Error switching audio device:', error);
    }
  };

  const switchVideoDevice = async (newVideoDeviceId) => {
    const stream = await videoStream;
    setVideoDeviceId(newVideoDeviceId);
    try {
      if (stream?.active) {
        stopVideo();
        const newStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: newVideoDeviceId } });
        await produceVideo(newStream);
        setVideoStream(newStream);
      }
    } catch (error) {
      console.error('Error switching video device:', error);
    }
  };

  const init = async () => {
    try {
      await setCallStatus('in-call');
      await setShowPanel(false);
      await setOver(true);

      window.consumers = [];
      await setStreams([]);

      dispatch({ type: Actions.RTC_ROOM_ID, roomID: meeting?.meeting?._id });

      const { producers, consumers, peers } = await io.request('join', { roomID: meeting?.meeting?._id });

      dispatch({ type: Actions.RTC_CONSUMERS, consumers, peers });

      const routerRtpCapabilities = await io.request('getRouterRtpCapabilities');
      const device = new mediasoup.Device();
      await device.load({ routerRtpCapabilities });

      setDevice(device);

      await subscribe(device);

      dispatch({ type: Actions.RTC_PRODUCERS, producers: producers || [] });

      const data = await io.request('createProducerTransport', {
        forceTcp: false,
        rtpCapabilities: device.rtpCapabilities,
        roomID: meeting?.meeting?._id,
      });

      if (data.error) {
        console.error(data.error);
        return;
      }

      transport = device?.createSendTransport(data);
      console.log(transport, 'Transport');
      transport?.on('connect', async ({ dtlsParameters }, callback, errback) => {
        io.request('connectProducerTransport', { dtlsParameters }).then(callback).catch(errback);
      });

      transport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
        try {
          const { id } = await io.request('produce', {
            transportId: transport.id,
            kind,
            rtpParameters,
            roomID: meeting?.meeting?._id,
            isScreen: appData && appData.isScreen,
          });
          callback({ id });
        } catch (err) {
          errback(err);
        }
      });

      transport.on('connectionstatechange', (state) => {
        switch (state) {
          case 'connecting':
            break;

          case 'connected':
            // document.querySelector('#local_video').srcObject = stream;
            break;

          case 'failed':
            transport.close();
            break;

          default:
            break;
        }
      });

      await produceAudio();
      await produceVideo();
      // time out for left meeting
      if (meeting?.workspace_member?.hoursAllocated <= meeting?.workspace_member?.hoursUsed && meeting?.workspace_member?.workspace?.plan !== 'pay_as_you_go' && meeting?.createdBy?._id?.toString() === user?._id?.toString()) {
        if (timeOutArray.length === 0) {
          // count how time left
          // eslint-disable-next-line no-unsafe-optional-chaining
          const timeLeft = meeting?.workspace_member?.hoursAllocated - meeting?.workspace_member?.hoursUsed;

          // left meeting when time left
          const timeOut = setTimeout(() => {
            // if (closingState && joined) close();
            toast.error('Workspace time is over. Please upgrade your plan to continue.', {
              position: 'top-center',
            });
            // navigate('/billing', { replace: true });
          }, [timeLeft * 60 * 60 * 1000]);

          // warning before 10 minutes left
          const timeOut10 = setTimeout(() => {
            toast.error('10 minutes left. Please upgrade your plan to continue.', {
              position: 'top-center',
            });
          }, [timeLeft * 60 * 60 * 1000 - 600000]);

          // warning before 5 minutes left
          const timeOut5 = setTimeout(() => {
            toast.error('5 minutes left. Please upgrade your plan to continue.', {
              position: 'top-center',
            });
          }, [timeLeft * 60 * 60 * 1000 - 300000]);

          setTimeOutArray([timeOut, timeOut10, timeOut5]);
        }
      }
    } catch (err) {
      console.log(err, 'Initialization');
    }
  };

  useEffect(() => {
    if (joined && !whisperSocket) {
      let isServerReady = false;
      let language = 'en';
      const uuid = generateUUID();
      const socket = new WebSocket(`${config.whisperUrl}`);

      socket.onerror = (error) => {
        console.error('WebSocket error occurred:', error);
      };

      socket.onclose = (event) => {
        console.warn('WebSocket connection closed:', event);
      };

      // eslint-disable-next-line func-names
      socket.onopen = function (e) {
        console.log('Connected!', uuid);
        setWhisperSocket(socket);
        socket.send(
          JSON.stringify({
            uid: uuid,
            language,
            task: 'transcribe',
            model: 'large-v3-turbo',
            use_vad: true,
          }),
        );
      };

      socket.onmessage = async (event) => {
        const data = JSON.parse(event.data);
        // console.log(data, 'event data');
        if (data.uid !== uuid) return;

        if (data.status === 'WAIT') {
          console.log(data.message, 'wait');
          return;
        }

        if (isServerReady === false) {
          console.log('Server not ready.');
          isServerReady = true;
          return;
        }

        if (language === null) {
          console.log(data.message);
          language = data.language;
          return;
        }

        if (data.message === 'DISCONNECT') {
          console.log(data.message, 'disconnect');
          return;
        }

        io.emit('transcriptSave', { audioData: data, roomID: meeting?.meeting?._id, user });
      };
    }
  }, [joined, meeting?.meeting?._id, io]);

  useEffect(() => {
    if (audioProccessorObject && whisperSocket && isCurrentPeerApproved === true) {
      console.log('audio process useEffect');
      audioProccessorObject.onaudioprocess = (event) => {
        // console.log('audio process 2', whisperSocket);
        if (!audioContextObject || !whisperSocket) return;

        const inputData = event.inputBuffer.getChannelData(0);
        const audioData16kHz = resampleTo16kHz(inputData, audioContextObject.sampleRate);

        // const avgVolume = inputData.reduce((sum, val) => sum + Math.abs(val), 0) / inputData.length;
        // const silenceThreshold = 0.02; // Experiment with this threshold

        // if (whisperSocket.readyState === 1 && avgVolume > silenceThreshold) {
        //   whisperSocket.send(audioData16kHz);
        // }
        if (whisperSocket.readyState === 1) {
          whisperSocket.send(audioData16kHz);
        }
      };
    }
  }, [audioProccessorObject, whisperSocket, peers?.[Object.keys(peers)?.filter((peerKey) => peers[peerKey]?.userID === user?._id)?.[0]]]);

  useEffect(() => {
    if (lastLeaveType === 'leave') setStreams(getGlobal().streams.filter((s) => s.socketID !== lastLeave));
    else setStreams(getGlobal().streams.filter((s) => s.producerID !== lastLeave));
  }, [lastLeave, lastLeaveType, setStreams, increment]);

  useEffect(() => {
    const init = async () => {
      try {
        if (!window.consumers) {
          window.consumers = [];
        }
        const newStreams = [];
        for (const producer of producers) {
          if (!window.consumers.includes(producer.producerID) && producer.roomID === meeting?.meeting?._id) {
            window.consumers.push(producer.producerID);

            const stream = await consume(window.transport, producer);

            stream.producerID = producer.producerID;
            stream.socketID = producer.socketID;
            stream.userID = producer.userID;

            newStreams.push(stream);

            io.request('resume', { producerID: producer.producerID, meetingID: meeting?.meeting?._id });
          }
        }
        setStreams([...getGlobal().streams, ...newStreams]);
      } catch (err) {
        console.log(err);
      }
    };
    init();
  }, [producers]);

  const consume = async (transport, producer) => {
    try {
      const { rtpCapabilities } = device;
      const data = await io.request('consume', {
        rtpCapabilities,
        socketID: producer.socketID,
        roomID: meeting?.meeting?._id,
        producerID: producer.producerID,
      });
      const {
        producerId, id, kind, rtpParameters,
      } = data;

      const codecOptions = {};
      const consumer = await transport.consume({
        id,
        producerId,
        kind,
        rtpParameters,
        codecOptions,
      });
      consumer.on('producerclose', () => {
        console.log('associated producer closed so consumer closed');
      });
      consumer.on('close', () => {
        console.log('consumer closed');
      });
      const stream = new MediaStream();
      stream.addTrack(consumer.track);
      stream.isVideo = kind === 'video';
      return stream;
    } catch (err) {
      console.log(err);
    }
  };

  const subscribe = async (device, socketID) => {
    try {
      const data = await io.request('createConsumerTransport', {
        forceTcp: false,
        roomID: meeting?.meeting?._id,
        socketID,
      });

      if (data.error) {
        console.error(data.error);
        return;
      }

      const transport = device.createRecvTransport(data);
      transport.on('connect', ({ dtlsParameters }, callback, errback) => {
        io.request('connectConsumerTransport', {
          transportId: transport.id,
          dtlsParameters,
          socketID,
        })
          .then(callback)
          .catch(errback);
      });

      transport.on('connectionstatechange', async (state) => {
        switch (state) {
          case 'connecting':
            break;

          case 'connected':
            // document.querySelector('#remote_video').srcObject = await stream;
            for (const producer of producers) {
              await io.request('resume', { producerID: producer.producerID });
            }
            break;

          case 'failed':
            transport.close();
            break;

          default:
            break;
        }
      });

      window.transport = transport;
    } catch (err) {
      console.log(err);
    }
  };

  const getDeviceLists = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const audioDevices = devices.filter((device) => device.kind === 'audioinput');
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      console.log('Audio Devices:', audioDevices);
      console.log('Video Devices:', videoDevices);

      return { audioDevices, videoDevices };
    } catch (error) {
      console.error('Error fetching devices:', error);
      return { audioDevices: [], videoDevices: [] };
    }
  };

  useEffect(() => {
    const fetchAudioDevices = async () => {
      const { audioDevices, videoDevices } = await getDeviceLists();
      setAudioDevices(audioDevices);
      setVideoDevices(videoDevices);
    };

    fetchAudioDevices();
    setShowCaption(true);
  }, []);

  const close = async () => {
    try {
      // Stop all audio and video tracks
      if (audioStream) {
        audioStream?.getTracks()?.forEach((track) => track.stop());
      }

      if (videoStream) {
        // videoStream?.getTracks()?.forEach((track) => track.stop());
        if (videoStream.getTracks && typeof videoStream.getTracks === 'function') {
          videoStream.getTracks().forEach((track) => track.stop());
        }
      }

      if (localStream) {
        localStream?.getTracks()?.forEach((track) => track.stop());
      }

      if (audio) {
        stopAudio();
      }

      if (video) {
        stopVideo();
      }

      if (isScreen) {
        stopScreen();
      }

      if (whisperSocket) whisperSocket.close();

      // Clear the streams
      setStreams([]);
      setLocalStream(null);

      // Close the transport and leave the room
      if (transport) {
        transport.close();
      }

      // Request to leave the room and perform post-close actions
      await io.request('leave', { roomID: meeting?.meeting?._id });
      postClose({ roomID: meeting?.meeting?._id, userID: counterpart?._id });

      // Perform navigations and state updates
      if (user?.isGuest) {
        handleLogout();
      }

      if (meeting?.meeting?.createdBy?._id?.toString() === user?._id?.toString()) {
        window.location.href = `${config.appUrl}/briefs/${meeting?.meeting?._id}?`;
      } else {
        window.location.href = `${config.appUrl}/`;
      }
      setJoined(false);
      setShowPanel(true);
      setCallStatus(null);

      // Dispatch the RTC leave action
      dispatch({ type: Actions.RTC_LEAVE });

      // clear timeouts
      timeOutArray.forEach((timeOut) => clearTimeout(timeOut));

      console.log('close action meeting');

      setCurrentMessageLength(0);

      // window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong. Please try to refresh the page.');
    }
  };

  // const isMounted = useRef(false);

  // useEffect(() => {
  //   // Function to handle beforeunload event
  //   const handleBeforeUnload = (event) => {
  //     if (joined) {
  //       console.log('User is joined, closing...');
  //       close();
  //     }
  //     // Optionally show confirmation dialog
  //     // event.preventDefault();
  //     // event.returnValue = '';
  //   };

  //   // Function to handle offline event
  //   const handleOffline = () => {
  //     if (joined) {
  //       console.log('User went offline, closing...');
  //       close();
  //     }
  //   };

  //   // Add event listeners
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('offline', handleOffline);

  //   // Cleanup on unmount
  //   return () => {
  //     if (isMounted.current) {
  //       // Ensure cleanup only happens if mounted
  //       if (joined) {
  //         close();
  //       }
  //     }
  //     // Remove event listeners
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('offline', handleOffline);
  //     isMounted.current = true; // Mark as mounted after the first render
  //   };
  // }, [joined]); // Re-run the effect if `joined` or `close` changes

  useEffect(() => {
    if (isCurrentPeerApproved === false) {
      close();
      toast.error('Your meeting has been rejected.', {
        position: 'top-center',
      });
    }
    // else if (isCurrentPeerApproved === true) {
    //   toast.success('Your meeting has been approved.', {
    //     position: 'top-center',
    //   });
    // }
  }, [peers?.[Object.keys(peers)?.filter((peerKey) => peers[peerKey]?.userID === user?._id)?.[0]]]);

  useEffect(() => {
    if (meeting?.workspace_member?.hoursAllocated && meeting?.workspace_member?.hoursUsed) {
      if (meeting?.workspace_member?.hoursAllocated <= meeting?.workspace_member?.hoursUsed && meeting?.workspace_member?.workspace?.plan !== 'pay_as_you_go') {
        // if (closingState && joined) close();
        toast.error('Your workspace is not eligible for this meeting. Please upgrade your plan.', {
          position: 'top-center',
        });
        // setTimeout(() => {
        // navigate('/billing', { replace: true });
        // }, 3000);
      }
    } else if (meeting) {
      if (!meeting?.workspace_member) {
        // if (closingState && joined) close();
        toast.error('Your workspace is not eligible for this meeting. Please upgrade your plan.', {
          position: 'top-center',
        });
        // setTimeout(() => {
        // navigate('/billing', { replace: true });
        // }, 3000);
      }
    }
  }, [meeting]);

  if ((meeting?.meeting?.cronJobFinish && meeting?.meeting?.peers?.length === 0)) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center gap-6">
        <div className="text-2xl font-bold text-[#173300]">Meeting Ended By Host</div>
        <div className="flex gap-6 items-center">
          <a href={`${config.appUrl}`} className="hover:text-[#173300]">
            Back to Home
          </a>
          <a href={`${config.appUrl}/briefs/${meeting?.meeting?._id}`} className="text-center text-sm py-2 px-4 bg-[#173300] text-[#ffed74] hover:text-[#ffed74] rounded-lg">
            View Details
          </a>
        </div>
      </div>
    );
  }

  if (callDirection === 'incoming' && !joined) {
    return (
      <div className="content uk-flex uk-flex-column uk-flex-center uk-flex-middle h-full">
        <Ringing
          incoming
          meetingID={meeting?.meeting?._id}
          onJoin={() => {
            setJoined(true);
            init();
          }}
        />
      </div>
    );
  }

  if (callDirection === 'outgoing' && !joined) {
    return (
      <div className="content uk-flex uk-flex-column uk-flex-center uk-flex-middle h-full">
        <Ringing incoming={false} meetingID={meeting?.meeting?._id} />
      </div>
    );
  }

  if (!joined) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center gap-6">
        <Join
          onJoin={() => {
            setJoined(true);
            init();
          }}
          meeting={meeting}
        />
      </div>
    );
  }

  if (peers?.[Object.keys(peers)?.filter((peerKey) => peers[peerKey]?.userID === user?.id || peers[peerKey]?.userID === user?._id)?.[0]]?.requestJoin && (user?.id !== meeting?.meeting?.createdBy?._id || user?._id !== meeting?.meeting?.createdBy?._id)) {
    return (
      <div
        // style={{
        //   backgroundImage: "url('/image/bg/meeting-bg.jpeg')",
        // }}
        className="streams bg-cover bg-no-repeat uk-flex uk-flex-middle uk-flex-center uk-flex-column bg-[rgb(32,33,36)] h-screen"
      >
        <div className="p-6 rounded bg-[#ffffff3a] backdrop-blur-[5px]">
          <p className="text-4xl font-bold text-white animate-pulse">Waiting for host approval ...</p>
        </div>
      </div>
    );
  }
  return (
    <div
      className="flex h-screen bg-cover bg-no-repeat bg-[rgb(32,33,36)]"
    // style={{
    //   backgroundImage: "url('/image/bg/meeting-bg.jpeg')",
    // }}
    >
      <div className="meeting-main uk-flex uk-flex-column">
        {isGrid && <TopBarTransparent localStream={localStream} video={video} isScreen={isScreen} audio={audio} videoStream={videoStream} />}
        {/* {!isGrid && topBar && <TopBar localStream={localStream} video={video} showPanel={showPanel} setShowPanel={setShowPanel} isScreen={isScreen} topBar={topBar} setTopBar={setTopBar} streams={streams} setOver={setOver} />} */}
        <Streams
          isGrid={isGrid}
          streams={streams}
          localStream={localStream}
          isVideo={video}
          isScreen={isScreen}
          isMaximized={isMaximized}
        >

          <div className="flex gap-2.5 p-4 bg-black rounded-xl fixed bottom-4 z-50">

            <AudioButton audio={audio} stopAudio={stopAudio} getAudio={getAudio} produceAudio={produceAudio} switchAudioDevice={switchAudioDevice} audioDevices={audioDevices} audioDeviceId={audioDeviceId} audioSelectListShow={audioSelectListShow} setAudioSelectListShow={setAudioSelectListShow} />

            <VideoButton video={video} videoDeviceId={videoDeviceId} videoDevices={videoDevices} videoSelectListShow={videoSelectListShow} setVideoSelectListShow={setVideoSelectListShow} switchVideoDevice={switchVideoDevice} getVideo={getVideo} stopVideo={stopVideo} produceVideo={produceVideo} />

            <div
              onClick={() => (isScreen ? stopScreen() : getScreen().then((stream) => produceScreen(stream)))}
              className={`cursor-pointer w-12 h-12 p-3 rounded-xl justify-center items-center flex ${isScreen ? 'bg-white hover:bg-opacity-90' : 'bg-white text-white bg-opacity-25 hover:bg-opacity-20'}`}
              style={{
                borderRadius: isScreen ? '0.75rem' : '1.7rem', // Use numeric values for smooth interpolation
                transition: 'border-radius 0.4s ease-in-out, box-shadow 0.4s ease-in-out', // Explicit transition for border-radius and box-shadow
                boxShadow: isScreen ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none',
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="monitor-01">
                  <path id="Icon" d="M8 21H16M12 17V21M6.8 17H17.2C18.8802 17 19.7202 17 20.362 16.673C20.9265 16.3854 21.3854 15.9265 21.673 15.362C22 14.7202 22 13.8802 22 12.2V7.8C22 6.11984 22 5.27976 21.673 4.63803C21.3854 4.07354 20.9265 3.6146 20.362 3.32698C19.7202 3 18.8802 3 17.2 3H6.8C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8V12.2C2 13.8802 2 14.7202 2.32698 15.362C2.6146 15.9265 3.07354 16.3854 3.63803 16.673C4.27976 17 5.11984 17 6.8 17Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </div>

            <div
              onClick={() => setShowCaption(!showCaption)}
              className={`w-12 h-12 p-3 rounded-xl justify-center items-center flex ${!showCaption ? 'text-white bg-white bg-opacity-25 hover:bg-opacity-20' : 'text-black bg-white hover:bg-opacity-90'} cursor-pointer`}
              style={{
                borderRadius: showCaption ? '0.75rem' : '1.7rem', // Use numeric values for smooth interpolation
                transition: 'border-radius 0.4s ease-in-out, box-shadow 0.4s ease-in-out', // Explicit transition for border-radius and box-shadow
                boxShadow: showCaption ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none',
              }}
            >
              <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                <g id="SVGRepo_iconCarrier">
                  <path d="M6 10v4c0 1.103.897 2 2 2h3v-2H8v-4h3V8H8c-1.103 0-2 .897-2 2zm7 0v4c0 1.103.897 2 2 2h3v-2h-3v-4h3V8h-3c-1.103 0-2 .897-2 2z" />
                  <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM4 18V6h16l.002 12H4z" />
                </g>
              </svg>
            </div>

            <EmojiButton />
            {/* <div className="close" onClick={close}>
            <FiPhoneOff />
          </div> */}
            <InviteButton />

            <SettingsButton videoDevices={videoDevices} videoDeviceId={videoDeviceId} switchVideoDevice={switchVideoDevice} audioDevices={audioDevices} audioDeviceId={audioDeviceId} switchAudioDevice={switchAudioDevice} />
            <div
              onClick={close}
              className="w-12 h-12 p-3 rounded-xl justify-center items-center flex bg-red-500 cursor-pointer"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="phone-call-01">
                  <g id="Solid">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.0682 5.80859C13.1739 5.26653 13.6991 4.91284 14.2412 5.01859C15.4132 5.24727 16.4904 5.8205 17.3348 6.66492C18.1792 7.50933 18.7525 8.58651 18.9812 9.75859C19.0869 10.3007 18.7332 10.8258 18.1912 10.9316C17.6491 11.0373 17.1239 10.6836 17.0182 10.1416C16.8657 9.36019 16.4836 8.64207 15.9206 8.07913C15.3577 7.51619 14.6396 7.13403 13.8582 6.98158C13.3161 6.87582 12.9624 6.35066 13.0682 5.80859Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.0558 1.88968C13.1168 1.34077 13.6112 0.945223 14.1601 1.0062C16.4148 1.25669 18.5174 2.2664 20.1226 3.86954C21.7277 5.47269 22.7401 7.57399 22.9934 9.82842C23.0551 10.3773 22.6602 10.8722 22.1113 10.9338C21.5625 10.9955 21.0676 10.6006 21.0059 10.0518C20.8033 8.2482 19.9934 6.56717 18.7092 5.28465C17.4251 4.00213 15.743 3.19436 13.9392 2.99397C13.3903 2.93299 12.9948 2.43858 13.0558 1.88968Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.33755 17.7522C3.90364 15.3183 2.13382 12.2874 1.19324 8.97814C1.04573 8.45912 0.931344 8.05665 0.9297 7.50476C0.927823 6.87399 1.13333 6.08375 1.44226 5.5338C1.97354 4.58803 3.11431 3.37598 4.09963 2.87761C4.95097 2.447 5.95637 2.447 6.80771 2.87761C7.64337 3.30028 8.60193 4.27868 9.11085 5.06143C9.75734 6.05577 9.75734 7.33764 9.11085 8.33197C8.93761 8.59842 8.69068 8.84493 8.40402 9.13111C8.31476 9.22022 8.21651 9.28461 8.28176 9.4205C8.92958 10.7698 9.81307 12.0354 10.9337 13.156C12.0544 14.2767 13.32 15.1602 14.6693 15.808C14.809 15.8751 14.8662 15.7784 14.9587 15.6858C15.2449 15.3991 15.4914 15.1522 15.7578 14.9789C16.7522 14.3325 18.034 14.3325 19.0284 14.9789C19.8111 15.4879 20.7895 16.4464 21.2122 17.2821C21.6428 18.1334 21.6428 19.1388 21.2122 19.9902C20.7175 20.9681 19.5154 22.1086 18.556 22.6475C18.0061 22.9565 17.2158 23.162 16.585 23.1601C16.0331 23.1585 15.6307 23.0441 15.1117 22.8966C11.8024 21.9561 8.77144 20.1861 6.33755 17.7522Z" fill="white" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </Streams>
        {!isGrid && !topBar && <TopBar localStream={localStream} />}
        {addPeers && <AddPeers onClose={() => setAddPeers(false)} />}
      </div>

      <MeetingBottomRight className="lg:flex-row flex-col lg:bottom-10 bottom-[100px] absolute -z-1" chatRoomLoading={chatRoomLoading} chatRoomId={chatRoomId} />
      {agenda && <MeetingBottomLeft agenda={agenda} />}

      <MeetingPeople streams={streams} isGrid={isGrid} roomID={meeting?.meeting?._id} meeting={meeting} user={user} dispatch={dispatch} io={io} />
    </div>
  );
}

export default Meeting;
