/* eslint-disable no-undef */
/* eslint-disable brace-style */
/* eslint-disable import/prefer-default-export */
import { clsx } from 'clsx';
import md5 from 'md5';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function generateTimeArray(startTimeStr, endTimeStr, timeFormat, slot) {
  // Parse start and end time strings
  const startTime = parseTimeString(startTimeStr);
  const endTime = parseTimeString(endTimeStr);

  // Initialize result array
  const timeArray = [];

  // Set initial time to the start time
  const currentTime = new Date(startTime);

  // Loop until the current time reaches the end time
  while (currentTime <= endTime) {
    // Add current time to the result array
    timeArray.push(formatTime(currentTime, timeFormat));

    // Increment current time by the slot duration
    currentTime.setMinutes(currentTime.getMinutes() + slot);
  }

  return timeArray;
}

export function formatTime(date, timeFormat) {
  // Get hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes
  let hoursStr;
  let minutesStr;
  let period;

  if (timeFormat === '12h') {
    period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    hoursStr = hours.toString();
    minutesStr = minutes.toString().padStart(2, '0');
    return `${hoursStr}:${minutesStr} ${period}`;
  }
  hoursStr = hours.toString().padStart(2, '0');
  minutesStr = minutes.toString().padStart(2, '0');
  return `${hoursStr}:${minutesStr}`;
}

export function parseTimeString(timeStr) {
  if (!timeStr) {
    return;
  }

  // Split the time string into hours, minutes, and am/pm
  const parts = timeStr.split(/:|\s/); // Split by colon or space
  let hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const ampm = parts[2] ? parts[2].toLowerCase() : null; // Check if AM/PM exists

  // Adjust hours if 12h format and PM
  if (ampm === 'pm' && hours < 12) {
    hours += 12;
  }
  // Adjust hours if 12h format and AM and 12AM
  else if (ampm === 'am' && hours === 12) {
    hours = 0;
  }

  // Create a new Date object with today's date and the parsed time
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function convertTo24Hour(timeStr) {
  if (!timeStr) {
    return;
  }

  // Trim the input string to remove any leading or trailing whitespace
  timeStr = timeStr?.trim();

  // Check if the time is already in 24-hour format
  const twentyFourHourFormat = /^([01]\d|2[0-3]):[0-5]\d$/;
  if (twentyFourHourFormat.test(timeStr)) {
    return timeStr;
  }

  // Extract hours, minutes, and period (AM/PM) using a regular expression
  const timeParts = timeStr?.match(/^(\d{1,2}):(\d{2})\s*([ap]m)$/i);

  // Check if the input string matched the expected format
  if (!timeParts) {
    console.error(`Invalid time format: ${timeStr}`);
    return null; // Return null or handle the error as needed
  }

  let hours = parseInt(timeParts[1], 10);
  const minutes = timeParts[2];
  const period = timeParts[3].toLowerCase();

  // Convert hours to 24-hour format
  if (period === 'pm' && hours !== 12) {
    hours += 12;
  } else if (period === 'am' && hours === 12) {
    hours = 0;
  }

  // Format hours to ensure it is two digits
  const formattedHours = hours.toString().padStart(2, '0');

  // Return the formatted time string
  return `${formattedHours}:${minutes}`;
}

export function convertTo12Hour(timeStr) {
  if (!timeStr) {
    return;
  }

  // Check if the time is already in 12-hour format
  const twelveHourFormat = /^(\d{1,2}):(\d{2})\s*(am|pm)$/i;
  if (twelveHourFormat.test(timeStr)) {
    return timeStr;
  }

  // Extract hours and minutes using a regular expression
  const timeParts = timeStr.match(/^(\d{2}):(\d{2})$/);

  // Check if the input string matched the expected format
  if (!timeParts) {
    console.error(`Invalid time format: ${timeStr}`);
    return null; // Return null or handle the error as needed
  }

  let hours = parseInt(timeParts[1], 10);
  const minutes = timeParts[2];
  let period = 'am';

  // Determine the period (AM/PM) and convert hours to 12-hour format
  if (hours >= 12) {
    period = 'pm';
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  // Format hours to ensure it is two digits if needed
  const formattedHours = hours.toString();

  // Return the formatted time string
  return `${formattedHours}:${minutes} ${period}`;
}

export function isTimeLater(timeString) {
  if (!timeString) {
    return false;
  }
  const time = convertTo24Hour(timeString);

  // Get the current time
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();

  // Parse the time string
  const [inputHours, inputMinutes] = time.split(':').map(Number);

  // Compare the hours and minutes
  if (inputHours > currentHours) {
    return true;
  } if (inputHours === currentHours && inputMinutes > currentMinutes) {
    return true;
  }
  return false;
}

export function formatDateString(dateStr) {
  // Create a Date object from the input date string
  const dateObj = new Date(dateStr);

  // Extract the month, day, and year
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();

  // Format the date as MM-DD-YYYY
  return `${month}-${day}-${year}`;
}

export function convertTimeStringToMinutes(timeString) {
  const parts = timeString.toLowerCase().split(' ');
  let totalMinutes = 0;

  for (let i = 0; i < parts.length; i += 2) {
    const value = parseInt(parts[i], 10);
    const unit = parts[i + 1];

    if (Number.isNaN(value)) {
      throw new Error('Invalid time format');
    }

    if (unit.startsWith('hour')) {
      totalMinutes += value * 60;
    } else if (unit.startsWith('min')) {
      totalMinutes += value;
    } else {
      throw new Error('Invalid time unit');
    }
  }

  return totalMinutes;
}

export function getGravatarUrl(username, email, size = 80) {
  // Generate hash using md5 package
  const hash = md5(email?.trim()?.toLowerCase() || 'default@email.com');

  // Encode the username for the fallback URL
  const encodedUsername = encodeURIComponent(username);

  // Construct the Gravatar URL with UI Avatars fallback
  const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=${size}&d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${encodedUsername}/${size}/random`;

  return gravatarUrl;
}

export const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

export function generateColorShades(baseHexColor) {
  // Optimized hex to HSL conversion
  function hexToHSL(hex) {
    const r = parseInt(hex?.slice(1, 3), 16) / 255;
    const g = parseInt(hex?.slice(3, 5), 16) / 255;
    const b = parseInt(hex?.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const l = (max + min) / 2;

    if (max === min) {
      return { hue: 0, saturation: 0, lightness: Math.round(l * 100) };
    }

    const d = max - min;
    const s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    let h = 0;
    switch (max) {
      case r: h = ((g - b) / d + (g < b ? 6 : 0)); break;
      case g: h = ((b - r) / d + 2); break;
      case b: h = ((r - g) / d + 4); break;
      default:
        h = 0;
        break;
    }

    return {
      hue: Math.round(h * 60),
      saturation: Math.round(s * 100),
      lightness: Math.round(l * 100),
    };
  }

  // Optimized HSL to hex conversion
  function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;
    const a = s * Math.min(l, 1 - l);
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  // Expanded color ranges
  const colorRanges = [
    { name: 'Red', min: 355, max: 10 },
    { name: 'Scarlet', min: 11, max: 20 },
    { name: 'Orange Red', min: 21, max: 30 },
    { name: 'Orange', min: 31, max: 40 },
    { name: 'Golden Yellow', min: 41, max: 50 },
    { name: 'Yellow', min: 51, max: 60 },
    { name: 'Lime', min: 61, max: 80 },
    { name: 'Green', min: 81, max: 140 },
    { name: 'Emerald', min: 141, max: 160 },
    { name: 'Cyan', min: 161, max: 180 },
    { name: 'Sky Blue', min: 181, max: 200 },
    { name: 'Blue', min: 201, max: 220 },
    { name: 'Royal Blue', min: 221, max: 240 },
    { name: 'Indigo', min: 241, max: 260 },
    { name: 'Purple', min: 261, max: 280 },
    { name: 'Violet', min: 281, max: 300 },
    { name: 'Magenta', min: 301, max: 320 },
    { name: 'Pink', min: 321, max: 340 },
    { name: 'Rose', min: 341, max: 354 },
  ];

  function getColorName({ hue, saturation, lightness }) {
    if (saturation < 5) {
      if (lightness < 10) return 'Black';
      if (lightness > 90) return 'White';
      return lightness < 50 ? 'Dark Gray' : 'Light Gray';
    }

    if (saturation < 15) {
      return 'Gray';
    }

    // Handle red which crosses 0/360 boundary
    if (hue >= 355 || hue <= 10) return 'Red';

    // Find matching color range
    const colorMatch = colorRanges.find((range) => hue >= range.min && hue <= range.max);
    return colorMatch ? colorMatch.name : 'Unknown';
  }

  const lightnessValues = {
    50: 96,
    100: 90,
    200: 80,
    300: 70,
    400: 60,
    500: 50,
    600: 40,
    700: 30,
    800: 20,
    900: 10,
  };

  const baseHSL = hexToHSL(baseHexColor);

  // Find closest shade using array reduce
  const baseShade = Object.entries(lightnessValues)
    .reduce((closest, [name, lightness]) => {
      const diff = Math.abs(lightness - baseHSL.lightness);
      return diff < closest.diff ? { name, diff } : closest;
    }, { name: '500', diff: Infinity });

  // Generate shades with map
  const shades = Object.entries(lightnessValues).map(([name, lightness]) => ({
    name,
    hexcode: name === baseShade.name
      ? baseHexColor.toLowerCase()
      : hslToHex(baseHSL.hue, baseHSL.saturation, lightness),
    hsl: {
      hue: baseHSL.hue,
      saturation: baseHSL.saturation,
      lightness,
    },
  }));

  return {
    name: getColorName(baseHSL),
    shades,
  };
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}
export function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.saybriefly.com; secure; SameSite=None;`;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=meet.saybriefly.com; secure; SameSite=None;`;
}
