/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */

import AudioDeviceList from './AudioDeviceList';

/* eslint-disable react/jsx-indent */
export default function AudioButton({ audio, stopAudio, getAudio, produceAudio, switchAudioDevice, audioDevices, audioDeviceId, audioSelectListShow, setAudioSelectListShow }) {
    return (
        <div className="relative">
            {
                audioSelectListShow && (
                    <div className="absolute -top-10 left-0 transform -translate-x-1 -translate-y-6 rounded-xl">

                        <AudioDeviceList audioDevices={audioDevices} audioDeviceId={audioDeviceId} switchAudioDevice={switchAudioDevice} />
                    </div>
                )
            }

            <div
                className={`flex items justify-center items-center gap-2 rounded-xl ${audio ? 'bg-opacity-20 bg-white hover:bg-opacity-30' : 'bg-red-800 hover:bg-red-900'}`}
                style={{
                    borderRadius: audio ? '0.75rem' : '1.7rem', // Use numeric values for smooth interpolation
                    transition: 'border-radius 0.4s ease-in-out, box-shadow 0.4s ease-in-out', // Explicit transition for border-radius and box-shadow
                    boxShadow: audio ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none',
                }}
            >
                <div className="pl-2 cursor-pointer" onClick={() => setAudioSelectListShow((prev) => !prev)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                        <g id="SVGRepo_iconCarrier">
                            <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill="#ffffff" />
                        </g>
                    </svg>
                </div>
                <div
                    onClick={() => (audio ? stopAudio() : getAudio().then((stream) => produceAudio(stream)))}
                    className={`cursor-pointer w-12 h-12 p-3 rounded-xl justify-center items-center flex bg-white ${audio ? '' : ' text-white bg-opacity-25'}`}
                    style={{
                        borderRadius: audio ? '0.75rem' : '1.7rem', // Use numeric values for smooth interpolation
                        transition: 'all 0.4s ease-in-out, box-shadow 0.4s ease-in-out', // Explicit transition for border-radius and box-shadow
                        boxShadow: audio ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none',
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="microphone-02">
                            <g id="Solid">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4 11C4.55228 11 5 11.4477 5 12V13C5 16.866 8.13401 20 12 20C15.866 20 19 16.866 19 13V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13V12C3 11.4477 3.44772 11 4 11Z" fill="currentColor" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V13C17 15.7614 14.7614 18 12 18C9.23858 18 7 15.7614 7 13V7Z" fill="currentColor" />
                            </g>
                        </g>
                    </svg>
                </div>

            </div>
        </div>
    );
}
