/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getMeetingById from '../../../actions/getMeetingById';
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import AvatarGroup from '../../../components/ui/avatar-group';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import Config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';
import BackButton from '../components/back-button';
import BriefContainer from '../components/brief-container';
import ContactDetails from '../components/contact-details';
import CreateMeetingButton from '../components/create-meeting-button';
import EditProjectSpace from '../components/edit-project-space';
import MeetingHistoryDetails from '../components/meeting-history-details';
import MeetingStats from '../components/meeting-stats';
import MeetingHistory from '../components/meetings-history';
import ProjectCard from '../components/project-card';
import SearchInput from '../components/search-input';
import ViewMeetingDetailsModal from '../components/view-meeting-details-modal';
import Layout from '../layout';

function ProjectSpaceDetails() {
  const { id: resourceId } = useParams();
  const [user] = useGlobal('user');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [resourceDetails, setResourceDetails] = useState(null);

  const [contacts, setContacts] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [briefs, setBriefs] = useState([]);

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [meetingData, setMeetingData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isMeetingLoading, setIsMeetingLoading] = useState(false);
  const [value, setValue] = useState([new DateObject()]);

  const [selectedContact, setSelectedContact] = useState(null);
  const [searchMeetings, setSearchMeetings] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);

  const [selectedBrief, setSelectedBrief] = useState(null);

  const [selectedFolder, setSelectedFolder] = useState('user');

  const [stats, setStats] = useState(null);

  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    setPage(1);
    setSearchUser('');
    setSearchMeetings('');
  }, [selectedFolder]);

  const getResourceData = async () => {
    try {
      const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource/${resourceId}`);
      if (data?.data) {
        setResourceDetails(data?.data);
        if (!data?.data?.default) {
          setSelectedFolder('meeting-history');
        }
        setRefetch(false);
      } else {
        toast.error('Something went wrong', {
          position: 'top-center',
          description: 'Resource not found!',
        });
        navigate(-1);
      }
    } catch (err) {
      navigate(-1);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (resourceId) {
      getResourceData();
    }
  }, [resourceId]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        try {
          const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource_users/${resourceId}?page=${page}${searchUser ? `&search=${searchUser}` : ''}`);
          if (data?.success) {
            const sortedUser = data?.data?.sort((a, b) => {
              if (a.email === user.email) return -1;
              if (b.email === user.email) return 1;
              return a.email.localeCompare(b.email);
            });
            setContacts(sortedUser);
            console.log(sortedUser);
            setSelectedContact(sortedUser[0]);
            setRefetch(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getData();
    }
  }, [page, refetch, selectedFolder, resourceId, searchUser]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        setIsMeetingLoading(true);
        try {
          const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource_meetings/${resourceId}?date=${new Date().toLocaleDateString('en-CA')}${searchMeetings ? `&search=${searchMeetings}` : ''}&page=${page}${value[0] && value[1] ? `&from=${value[0].format('MM-DD-YYYY')}&to=${value[1].format('MM-DD-YYYY')}` : ''}`);
          if (data?.success) {
            setMeetings(data?.data);
            setSelectedMeeting(data?.data[0]);
            setRefetch(false);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsMeetingLoading(false);
        }
      };
      getData();
    }
  }, [page, refetch, selectedFolder, resourceId, searchMeetings, value]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        try {
          const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource_meeting_briefs/${resourceId}`);
          if (data?.success) {
            setBriefs(data?.data);
            setRefetch(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getData();
    }
  }, [page, refetch, selectedFolder, resourceId]);

  const userAvatars = resourceDetails?.users?.map((user) => ({
    src: user.picture
      ? `${Config.url || ''}/${user.picture.location}`
      : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email),
    fallback: (user?.firstName?.charAt(0) || '') + (user?.lastName?.charAt(0) || ''),
    alt: '',
  }));

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        try {
          const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource_stats/${resourceId}`);
          if (data?.success) {
            setStats(data.stats);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getData();
    }
  }, [resourceId]);

  useEffect(() => {
    if (selectedMeeting?._id) {
      const getData = async () => {
        const { data: result } = await getMeetingById(selectedMeeting?._id);
        setMeetingData(result.data);
      };

      getData();
    }
  }, [selectedMeeting]);

  const handleArchive = async () => {
    try {
      const { data } = await axios.post(`${Config.url || ''}/api/v1/user/delete_resource/${resourceId}`);

      if (data?.success) {
        toast.success(data?.message, {
          position: 'top-center',
        });
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-full">
          <span className="size-8 border-4 border-t-[#fce657] border-[#173300] rounded-full animate-spin" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex justify-between">
        <BackButton />
        {
          !resourceDetails?.default && (
            <DropdownMenu>
              <DropdownMenuTrigger>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="dots-vertical">
                    <g id="Icon">
                      <path d="M10.0003 10.833C10.4606 10.833 10.8337 10.4599 10.8337 9.99967C10.8337 9.53944 10.4606 9.16634 10.0003 9.16634C9.54009 9.16634 9.16699 9.53944 9.16699 9.99967C9.16699 10.4599 9.54009 10.833 10.0003 10.833Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.0003 4.99967C10.4606 4.99967 10.8337 4.62658 10.8337 4.16634C10.8337 3.7061 10.4606 3.33301 10.0003 3.33301C9.54009 3.33301 9.16699 3.7061 9.16699 4.16634C9.16699 4.62658 9.54009 4.99967 10.0003 4.99967Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.0003 16.6663C10.4606 16.6663 10.8337 16.2932 10.8337 15.833C10.8337 15.3728 10.4606 14.9997 10.0003 14.9997C9.54009 14.9997 9.16699 15.3728 9.16699 15.833C9.16699 16.2932 9.54009 16.6663 10.0003 16.6663Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => setShowEditModal(true)}>
                  {/* Edit */}
                  <span className="text-[#101828] text-base font-medium font-inter leading-normal px-1.5">
                    Edit
                  </span>
                </DropdownMenuItem>
                {/* <DropdownMenuSeparator /> */}
                <DropdownMenuItem onClick={() => setShowArchiveAlert(true)}>
                  <span className="text-[#101828] text-base font-medium font-inter leading-normal px-1.5">
                    Archive
                  </span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      </div>
      {
        !resourceDetails?.default && (
          <div className="flex gap-4 mt-6">
            <AvatarGroup avatars={userAvatars} max={2} />

            <div className="flex gap-3 items-end">
              {resourceDetails?.users?.slice(0, 2)?.map((user, index) => (
                <div key={index} className="flex items-end">
                  <div className="flex flex-col">
                    <p
                      className="text-[#101828] text-sm font-medium leading-tight whitespace-nowrap capitalize"
                      id={`user-name-${user?._id}`}
                    >
                      {user?.firstName}
                      {' '}
                      {user?.lastName}
                    </p>
                    <p
                      className="text-[#475467] text-xs font-normal font-['Inter'] leading-[18px] truncate"
                      style={{
                        maxWidth: document.getElementById(`user-name-${user?._id}`)?.offsetWidth || '100px',
                      }}
                    >
                      {user?.email}
                    </p>
                  </div>
                  {index !== (resourceDetails?.users?.length || 0) - 1 && <span>,</span>}
                </div>
              ))}
              {resourceDetails?.users?.length > 2 && (
                <p className="text-sm whitespace-nowrap capitalize">
                  <span>
                    {(resourceDetails?.users?.length || 0) - 2}
                    {' '}
                    more
                  </span>
                </p>
              )}
            </div>
          </div>
        )
      }
      <div className="space-y-6 mt-6 pb-6">
        <h4 className="text-[#101828] text-2xl font-semibold font-inter leading-loose">
          {resourceDetails?.default ? 'Default Project Space' : resourceDetails?.name}
        </h4>

        <div className="flex lg:flex-row xl:flex-nowrap flex-wrap justify-between flex-col-reverse gap-6">
          <div className="space-y-6 w-full">
            <CreateMeetingButton
              userData={{
                name: `${contacts[1]?.firstName} ${contacts[1]?.lastName}`,
                email: contacts[1]?.email,
              }}
              resource={resourceId}
            />
            <div className="flex flex-wrap gap-4">
              <div onClick={() => setSelectedFolder('user')} className="w-[165px]">
                <ProjectCard
                  folderColor={resourceDetails?.default ? '#FFED74' : '#9EE870'}
                  title="User"
                  description=""
                  foldersData={[`Total: ${resourceDetails?.users?.length}`]}
                  folderDataClassName="scale-90 mt-3 -ml-10"
                />
              </div>

              <div onClick={() => setSelectedFolder('meeting-history')} className="w-[165px]">
                <ProjectCard
                  folderColor={resourceDetails?.default ? '#FFED74' : '#9EE870'}
                  title="Meeting History"
                  description=""
                  foldersData={[`Total: ${resourceDetails?.meetings?.length}`]}
                  folderDataClassName="scale-90 mt-3 -ml-10"
                />
              </div>
              <div onClick={() => setSelectedFolder('briefs')} className="w-[165px]">
                <ProjectCard
                  folderColor={resourceDetails?.default ? '#FFED74' : '#9EE870'}
                  title="Briefs"
                  description=""
                  foldersData={[`Total: ${briefs?.length}`]}
                  folderDataClassName="scale-90 mt-3 -ml-10"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            {
              stats && <MeetingStats data={stats} />
            }
          </div>
        </div>

        {
          selectedFolder === 'user' && (
            <div className="px-4 py-5 bg-[#f2f4f7] rounded-xl space-y-4">
              <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">User List</h4>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="space-y-4">
                  <SearchInput search={searchUser} setSearch={setSearchUser} />
                  {contacts?.map((contact, i) => (
                    <ClientListItem
                      key={i}
                      clientAvatar={contact?.picture?.location ? `${Config.url || ''}/${contact?.picture?.location}` : getGravatarUrl(`${contact?.firstName} ${contact?.lastName}`, contact?.email)}
                      clientName={`${contact?.firstName} ${contact?.lastName}`}
                      clientRole={`Member since ${moment(contact?.createdAt).format('MMM YYYY')}`}
                      isSelected={contact?._id === selectedContact?._id}
                      onClick={() => setSelectedContact(contact)}
                    />
                  ))}
                </div>
                <div className="bg-white rounded-xl lg:p-[25px] px-4">
                  {selectedContact && (
                    <ContactDetails setRefetch={setRefetch} selectedContact={{ id: selectedContact?._id, contact_user: selectedContact }} />
                  )}
                </div>
              </div>
            </div>
          )
        }

        {
          selectedFolder === 'meeting-history' && (
            <div className="py-1 bg-[#f2f4f7] rounded-xl space-y-4">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 lg:flex-1 overflow-y-scroll scroll-none">
                <div className="h-full lg:flex-1 overflow-y-scroll scroll-none flex flex-col gap-8">
                  <div className="flex-1 overflow-y-scroll scroll-none space-y-6 max-h-[450px] lg:max-h-[700px]">
                    <MeetingHistory borderColor={resourceDetails?.default ? '#ffed74' : '#9EE870'} data={meetings} isLoading={isMeetingLoading} page={page} setPage={setPage} value={value} setValue={setValue} selectedMeeting={selectedMeeting} setSelectedMeeting={setSelectedMeeting} search={searchMeetings} setSearch={setSearchMeetings} />
                  </div>
                </div>
                <div className="py-4">
                  {
                    (selectedMeeting && meetingData) && (
                      <MeetingHistoryDetails meetingData={meetingData} setShowDetails={setShowDetails} />
                    )
                  }
                </div>
              </div>
              {
                (selectedMeeting?._id && meetingData) && (
                  <ViewMeetingDetailsModal
                    open={showDetails}
                    setOpen={setShowDetails}
                    meetingData={meetingData}
                    meetingId={selectedMeeting?.meetingCode}
                  />
                )
              }
            </div>
          )
        }

        {
          selectedFolder === 'briefs' && (
            <div className="p-4 bg-[#f2f4f7] rounded-xl space-y-4">
              <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Briefs</h4>
              {
                briefs?.length === 0 && (
                  <p>
                    No briefs found.
                  </p>
                )
              }
              <BriefContainer
                selectedBrief={selectedBrief}
                setSelectedBrief={setSelectedBrief}
                briefs={briefs}
                isDefault={resourceDetails?.default}
              />
            </div>
          )
        }

      </div>

      {
        showArchiveAlert && (
          <AlertDialog open={showArchiveAlert} onOpenChange={setShowArchiveAlert}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete your project space.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleArchive}>Continue</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )
      }

      <EditProjectSpace open={showEditModal} setOpen={setShowEditModal} resourceId={resourceId} prevData={resourceDetails} getResourceData={getResourceData} />

    </Layout>
  );
}

function ClientListItem({
  clientAvatar,
  clientName,
  clientRole,
  isSelected,
  onClick,
}) {
  return (
    <div className={`w-full px-6 py-4 cursor-pointer ${isSelected ? 'bg-gray-50' : 'hover:bg-gray-50'} rounded-lg border-b border-[#eaecf0] flex items-center gap-3`} onClick={onClick}>
      <figure className="w-10 h-10 rounded-full flex justify-center items-center">
        <img src={clientAvatar} alt="" className="w-10 h-10 object-cover object-center relative rounded-full border border-black/10" />
      </figure>
      <div className="flex-col">
        <h3 className="text-[#101828] lg:text-base text-sm font-medium font-inter leading-normal capitalize">
          {clientName}
        </h3>
        <p className="text-[#475467] text-xs font-normal font-inter leading-[18px]">
          {clientRole}
        </p>
      </div>
    </div>
  );
}

export default ProjectSpaceDetails;
