/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
import { useGlobal } from 'reactn';
import AvatarGroup from '../../../components/ui/avatar-group';
import config from '../../../config';
import { cn, getGravatarUrl } from '../../../lib/utils';

export default function ProjectCard({
  folderColor = '#FFED74',
  title,
  description,
  meetings,
  foldersData = [],
  folderDataClassName,
  users = [],
  resource,
}) {
  const [user] = useGlobal('user');
  const userAvatars =
    users?.length > 0
      ? users?.filter((u) => u?._id?.toString() !== user?._id?.toString())?.map((user) => ({
        src: user.picture
          ? `${config.url || ''}/${user?.picture?.location}`
          : getGravatarUrl(`${user?.firstName} ${user?.lastName}`, user?.email),
        fallback: (user?.firstName?.charAt(0) || 'S') + (user?.lastName?.charAt(0) || 'B'),
        alt: '',
      }))
      : [];
  const isGuest = resource && resource?.createdBy?.toString() !== user?._id?.toString();
  // console.log(isGuest);
  return (
    <div className="cursor-pointer w-full flex relative rounded-bl-[40.44px] rounded-br-[40.44px] overflow-hidden">
      {/* Shape */}
      <svg
        className="w-full"
        width="268"
        height="208"
        viewBox="0 0 268 208"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2513_6)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.70975e-06 43.7825C-0.000197339 38.3249 -0.000375956 33.6163 0.372423 29.7383C0.765908 25.6452 1.63467 21.5741 3.98325 17.6566C7.48698 11.8123 13.0777 7.0607 19.9542 4.08288C24.5635 2.08682 29.3537 1.34846 34.1697 1.01404C38.7326 0.697187 44.2725 0.697347 50.6941 0.697532L99.2411 0.696414C106.472 0.688299 112.83 0.681162 118.677 2.39512C123.798 3.89632 128.462 6.34627 132.332 9.5677C136.751 13.2457 139.587 18.0826 142.812 23.5828L153.709 42.1086H197.849C207.655 42.1085 215.748 42.1084 222.34 42.5662C229.188 43.0417 235.482 44.0621 241.394 46.6224C250.563 50.5928 258.017 56.9283 262.689 64.7207C265.701 69.7455 266.902 75.0951 267.462 80.9147C268 86.5178 268 93.3961 268 101.73V148.14C268 156.474 268 163.352 267.462 168.955C266.902 174.775 265.701 180.125 262.689 185.149C258.017 192.942 250.563 199.277 241.394 203.248C235.482 205.808 229.188 206.828 222.34 207.304C215.748 207.762 207.655 207.762 197.849 207.761H70.1517C60.3457 207.762 52.2523 207.762 45.6596 207.304C38.8122 206.828 32.5178 205.808 26.6056 203.248C17.4369 199.277 9.98262 192.942 5.31098 185.149C2.29852 180.125 1.09783 174.775 0.53838 168.955C-0.000263751 163.352 -0.000140111 156.474 9.70975e-06 148.14V43.7825ZM97.7261 21.4042C107.355 21.4042 109.263 21.5393 110.733 21.9701C112.44 22.4705 113.994 23.2871 115.285 24.3609C116.395 25.2853 117.391 26.6758 121.697 33.9952L126.47 42.1086H24.3645C24.3699 37.2945 24.4072 34.0036 24.6551 31.4245C24.9255 28.6119 25.3845 27.5691 25.6914 27.0572C26.8593 25.1091 28.7229 23.5253 31.0151 22.5327C31.6174 22.2718 32.8443 21.8817 36.1537 21.6519C39.6052 21.4123 44.1402 21.4042 51.1637 21.4042H97.7261Z"
            fill={folderColor}
          />
          <g clipPath="url(#clip1_2513_6)">
            <path d="M127.271 13.0312H11.875V55.6309H127.271V13.0312Z" fill={folderColor} />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2513_6">
            <rect width="268" height="208" fill="white" />
          </clipPath>
          <clipPath id="clip1_2513_6">
            <rect width="117" height="43" fill="white" transform="translate(11 13)" />
          </clipPath>
        </defs>
      </svg>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <div className="flex space-x-[-22px] -ml-[11px] -mt-4">
          {foldersData?.slice(0, 3)?.map((item, i) => (
            <div
              key={i}
              className={cn(
                'w-[102.45px] h-[66.77px] px-[18.77px] py-[17.21px] origin-top-left rotate-[-8.25deg] bg-white rounded-2xl justify-start items-start gap-[7.82px] inline-flex shadow-[-10px_-9px_61px_-15px_rgba(16,24,40,0.27)]',
                folderDataClassName,
              )}
            >
              <div className="text-[#173300] text-[8.04px] font-semibold font-inter leading-3">{item}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[55%] w-full bg-[#173300] absolute bottom-0 p-4 space-y-2">
        <h4 className="text-[#eaecf0] text-xs font-medium font-['Inter'] leading-[18.48px]">{title}</h4>
        <p className="text-[#b0b0b0] text-[10.71px] font-light font-['Inter'] leading-[13.07px]">{description}</p>
        {meetings?.length > 0 && (
          <p className="text-[#b0b0b0] text-[10.71px] font-medium font-['Inter'] leading-[13.07px]">
            Total meetings: {meetings?.length}
          </p>
        )}

        {users.length > 0 && (
          <div className="flex gap-1 pt-2">
            <AvatarGroup
              className="-space-x-3"
              avatars={userAvatars}
              max={2}
              avatarClassName="size-[22px]"
              fallbackClassName="text-[9px]"
            />

            <div className="flex gap-1 items-end">
              {users?.filter((u) => u?._id?.toString() !== user?._id?.toString())?.slice(0, 2).map((user, index) => (
                <div key={index} className="flex items-end">
                  <div className="flex flex-col">
                    <p
                      className="text-white text-[9px] font-medium leading-tight whitespace-nowrap capitalize"
                      id={`user-name-${user?._id}`}
                    >
                      {user?.firstName} {user?.lastName}
                    </p>
                    <p
                      className="text-gray-200 text-[8px] font-normal truncate"
                      style={{
                        maxWidth: document.getElementById(`user-name-${user?._id}`)?.offsetWidth || '100px',
                      }}
                    >
                      {user?.email}
                    </p>
                  </div>
                  {index !== (users?.length || 0) - 1 && <span className="text-[8px]">,</span>}
                </div>
              ))}
              {users.length > 2 && (
                <p className="text-[8px] text-white whitespace-nowrap capitalize">
                  <span>{(users?.length || 0) - 2} more</span>
                </p>
              )}
            </div>
          </div>
        )}
        {isGuest && (
          <div className="">
            <div className="bg-[#eaecf0] text-[#173300] text-[8px] font-medium font-['Inter'] rounded-full py-1 w-10 text-center">
              <span>Guest</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
