/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import getUpcomingMeetings from '../../../actions/getUpcomingMeetings';
import { isTimeLater } from '../../../lib/utils';
import MeetingCardExtendedDefault from './meeting-card-extended-default';

function UpcomingMeetingsDefault() {
  const refreshMeetings = useGlobal('refreshMeetings')[0];
  const [meetingData, setMeetingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const { data } = await getUpcomingMeetings(
          1,
          10,
        );
        const now = moment();
        const filteredData = data?.data?.filter((item) => {
          const bookingDate = moment(item?.booking?.bookingDate);
          return (
            bookingDate.isAfter(now, 'day') || (bookingDate.isSame(now, 'day') && isTimeLater(item?.booking?.bookingTime))
          );
        });
        setMeetingData(filteredData || []);
      } catch (err) {
        console.error('Error fetching upcoming meetings:', err);
        setMeetingData([]);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [refreshMeetings]);

  return (
    <div className="space-y-6 mt-4">
      <div className="flex lg:flex-row flex-col lg:items-center gap-4 justify-between">
        <h5 className="text-gray-900 text-lg font-semibold leading-7">Upcoming Meetings</h5>
      </div>
      {isLoading ? (
        <p className="flex items-center gap-2">
          <span className="w-4 h-4 rounded-full border-t-black border-2 border-gray-500 block animate-spin" />
          <span className="animate-pulse">Loading upcoming meetings...</span>
        </p>
      ) : (
        <div>
          {meetingData?.length > 0 && (
            <div className="flex-1 overflow-y-scroll scroll-none flex flex-col">
              <div className="space-y-4 lg:flex-1 overflow-y-scroll scroll-none">
                <MeetingCardExtendedDefault data={meetingData[0]} />
                {
                  meetingData[1] && (
                    <MeetingCardExtendedDefault data={meetingData[1]} />
                  )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default UpcomingMeetingsDefault;
