/* eslint-disable import/no-unresolved */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import cancelMeeting from '../../../actions/cancelMeeting';
import getMeetingById from '../../../actions/getMeetingById';
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import config from '../../../config';
import { convertTo24Hour, getGravatarUrl } from '../../../lib/utils';
import ViewMeetingDetailsModal from './view-meeting-details-modal';

function MeetingCardExtendedDefault({ data }) {
  const user = useGlobal('user')[0];
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [showDetails, setShowDetails] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [meetingData, setMeetingData] = useState(null);

  const secondUser = data?.booking?.guests?.length > 0 ? data?.booking?.guests?.[0] : null;
  const userEmail = (secondUser ? secondUser?.email : data?.createdBy?.email) || data?.createdBy?.email;
  const userName = secondUser
    ? `${secondUser?.name}`
    : `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;

  const gravatarUrl = getGravatarUrl(userName, userEmail);
  useEffect(() => {
    if (data?._id) {
      const getData = async () => {
        const { data: result } = await getMeetingById(data?._id);
        setMeetingData(result.data);
      };

      getData();
    }
  }, [data]);

  const handleCancelMeeting = async () => {
    const requestData = {
      bookingId: meetingData.booking._id,
      scheduleId: meetingData.booking.schedule._id,
      cancelledBy: user.email,
    };
    try {
      const { data } = await cancelMeeting(requestData);
      console.log(data);
      toast.success('Meeting cancelled successfully', {
        position: 'top-center',
      });
      setRefreshMeetings(!refreshMeetings);
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    }
  };

  return (
    <>
      <div className="w-full p-5 bg-[#e7ece4] rounded-xl border border-[#eaecf0] font-inter">
        <div className="flex flex-col lg:flex-row gap-3 lg:items-center">
          <div className="flex gap-3">
            <div className="min-w-max">
              <div className="size-[40px] border-4 border-white rounded-full flex items-center justify-center relative">
                <img
                  className="w-full h-full object-cover object-center rounded-full"
                  src={gravatarUrl}
                  alt=""
                />
                {data?.users?.length > 1 && (
                  <div className="absolute bottom-0 right-0 size-4 rounded-full flex items-center justify-center text-xs font-semibold bg-[#FFED74]">
                    {(data?.users?.length || 0) - 1}
                  </div>
                )}
              </div>
            </div>
            <div className="space-y-1 lg:flex-1">
              <h4 className="text-[#101828] text-sm font-medium leading-tight font-inter">
                {userName}
              </h4>
              <a href={`mailto:${userEmail}`} className=" text-[#475467] hover:text-[#2c3440] text-sm font-normal leading-tight inline-flex max-w-32" title={userEmail}>
                <span className="lg:block hidden">
                  {userEmail.slice(0, 15)}
                  {userEmail.length > 15 && '...'}
                </span>
                <span className="lg:hidden">
                  {userEmail}
                </span>
              </a>

              <p className="text-[#475467] text-sm font-normal font-inter min-w-max leading-tight lowercase">
                {data?.booking?.bookingTime ? convertTo24Hour(data?.booking?.bookingTime) : moment(data?.startedAt).format('HH:mm - DD/MM/YYYY')}
                {data?.booking?.bookingDate && ` - ${moment(data?.booking?.bookingDate).format('DD/MM/YYYY')}`}
              </p>
            </div>
          </div>
          <div className="lg:min-w-max flex-grow w-full lg:text-right text-center flex lg:justify-end items-center gap-4">
            <button onClick={() => setShowDetails(true)} className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] text-[#344054] text-sm font-semibold leading-tight min-w-max">
              View Details
            </button>
            <div>
              <Link to={`${config.meetUrl}${data?.meetingCode}`} target="_blank" rel="noreferrer" className="h-9 lg:min-w-max w-full lg:inline flex gap-1 items-center justify-center px-3 py-2 bg-[#fce657] rounded-lg shadow border-2 border-[#efd215] text-[#173300] hover:text-[#173300d8] text-sm font-semibold font-inter leading-tight">
                Join Meeting Room
                {' '}
                {
                  data?.peers?.length > 0 && (
                    <span className="relative inline-flex size-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#173300] opacity-75" />
                      <span className="relative inline-flex rounded-full size-2 bg-[#173300]" />
                    </span>
                  )
                }
              </Link>
            </div>
          </div>
        </div>
      </div>
      {data?._id && (
        <ViewMeetingDetailsModal
          open={showDetails}
          setOpen={setShowDetails}
          meetingData={meetingData}
          meetingId={data?.meetingCode}
        />
      )}

      {
        showCancelAlert && (
          <AlertDialog open={showCancelAlert} onOpenChange={setShowCancelAlert}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Confirm Action</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to cancel this meeting? Once canceled, this action cannot be undone. Press
                  {' '}
                  <strong>Continue</strong>
                  {' '}
                  to confirm.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleCancelMeeting}>Continue</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )
      }
    </>
  );
}

export default MeetingCardExtendedDefault;
