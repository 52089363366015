import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useGlobal } from 'reactn';
import getMeetingById from '../../../actions/getMeetingById';
import getMeetingHistory from '../../../actions/getMeetingHistory';
import MeetingHistoryDetails from './meeting-history-details';
import MeetingHistory from './meetings-history';
import ViewMeetingDetailsModal from './view-meeting-details-modal';

function MeetingHistoryContainer() {
  const refreshMeetings = useGlobal('refreshMeetings')[0];
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [meetingData, setMeetingData] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState([new DateObject()]);
  const [search, setSearch] = useState('');

  const getData = async () => {
    try {
      const { data } = await getMeetingHistory(
        page,
        10,
        value[0] && value[1] && `from=${value[0].format('MM-DD-YYYY')}&to=${value[1].format('MM-DD-YYYY')}`,
        search,
      );
      // console.log(data.data);
      setHistoryData(data.data);
      setSelectedMeeting(data.data[0]);
    } catch (err) {
      console.log(err);
      setHistoryData([]);
      setSelectedMeeting(null);
    }
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      getData();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [page, value, search, refreshMeetings]);

  useEffect(() => {
    if (selectedMeeting?._id) {
      const getData = async () => {
        const { data: result } = await getMeetingById(selectedMeeting?._id);
        setMeetingData(result.data);
      };

      getData();
    }
  }, [selectedMeeting]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:flex-1 overflow-y-scroll scroll-none">
        <div className="h-full lg:flex-1 overflow-y-scroll scroll-none flex flex-col gap-8">
          <div className="flex-1 overflow-y-scroll scroll-none space-y-6">
            <MeetingHistory
              data={historyData}
              isLoading={isLoading}
              page={page}
              setPage={setPage}
              value={value}
              setValue={setValue}
              selectedMeeting={selectedMeeting}
              setSelectedMeeting={setSelectedMeeting}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        {selectedMeeting && (
          <MeetingHistoryDetails meetingData={meetingData} setShowDetails={setShowDetails} getData={getData} />
        )}
      </div>
      {selectedMeeting?._id && (
        <ViewMeetingDetailsModal
          open={showDetails}
          setOpen={setShowDetails}
          meetingData={meetingData}
          meetingId={selectedMeeting?.meetingCode}
        />
      )}
    </>
  );
}

export default MeetingHistoryContainer;
