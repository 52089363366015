/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import editWorkspace from '../../actions/editWorkspace';
import getUserInfo from '../../actions/getUserInfo';
import { Button } from '../../components/ui/button';
import './Login.sass';
import Input from './components/Input';

function Login() {
  const [workspaceName, setWorkspaceName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useGlobal('user');
  const workspaceId = user?.workspace?._id;
  const [entryPath, setEntryPath] = useGlobal('entryPath');
  const navigate = useNavigate();

  const userData = async () => {
    try {
      const { data: res } = await getUserInfo();
      setUser(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    userData();
  }, []);

  const handleCreateWorkSpace = async (e) => {
    e.preventDefault();
    if (!workspaceName || !workspaceId) return;
    try {
      setIsLoading(true);
      const { data: res } = await editWorkspace(workspaceId, {
        name: workspaceName,
        description: workspaceName,
      });
      if (res.success) {
        toast.success('Workspace created successfully.', {
          position: 'top-center',
        });
        if (new URL(window.location.href).searchParams.has('meetId')) {
          window.location.href = `https://meet.saybriefly.com/${new URL(window.location.href).searchParams.get(
            'meetId',
          )}`;
        } else if (entryPath) {
          if (entryPath?.startsWith('/meeting')) {
            window.location.href = `https://meet.saybriefly.com/${entryPath?.replace('/meeting/', '')}`;
          } else {
            navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
          }
        } else {
          navigate('/');
        }
        await setEntryPath(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 h-screen">
      <div
        className="flex flex-col justify-between p-6 items-center bg-contain bg-top bg-no-repeat"
        style={{
          backgroundImage: 'url("/image/bg/login-page-pattern.png")',
        }}
      >
        <div />
        <div className="login-inner lg:w-[360px] uk-padding-small" data-uk-scrollspy="cls: uk-animation-fade">
          <div className="mb-4 w-full space-y-3">
            <h4 className="text-[#101828] text-3xl font-semibold">Sign up</h4>
          </div>

          <div className="w-full">
            <form onSubmit={handleCreateWorkSpace}>
              <fieldset className="space-y-6">
                <Input
                  placeholder="Enter your workspace name"
                  label="Create Workspace Name*"
                  type="text"
                  value={workspaceName}
                  onChange={(e) => setWorkspaceName(e.target.value)}
                  required
                />
                <Button className="w-full">
                  {isLoading ? (
                    <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                  ) : (
                    <span>Get Started</span>
                  )}
                </Button>
              </fieldset>
            </form>

            {/* <div className="mt-6">
              <p className="text-center">
                Already have an account?
                {' '}
                <Link to="/login" onClick={() => setActivePage('login')} className="cursor-pointer text-[#323333]">Login now</Link>
              </p>
            </div> */}
          </div>
        </div>
        <div className="w-full">© SayBriefly {new Date().getFullYear()}</div>
      </div>
      <div
        className="lg:h-full hidden lg:block bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: 'url("/image/bg/auth-bg.png")',
        }}
      />
    </div>
  );
}

export default Login;
