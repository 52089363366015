import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import setAuthToken from '../../../actions/setAuthToken';
import setCurrentWorkspace from '../../../actions/setCurrentWorkspace';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import Config from '../../../config';
import SideNavigation from './side-navigation';
import UsageProgress from './usage-progress';
import CreateTeamSpaceModal from './create-teamspace-modal';

function Sidebar({ showSideBar }) {
  const [user, setUser] = useGlobal('user');
  const [token, setToken] = useGlobal('token');
  const [teamSpaces] = useGlobal('teamSpaces');
  const [openCreateTeamSpaceModal, setOpenCreateTeamSpaceModal] = useState(false);

  const selectedTeamSpace = teamSpaces?.find((space) => space?._id === user?.workspace?._id);

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    document.cookie = 'jwt_token=; path=/; domain=.saybriefly.com; secure; SameSite=None;';
  };

  async function getUser() {
    axios
      .get(`${Config.url || ''}/api/v1/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setUser(res.data.data);
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
  }

  const handleTeamSwitch = async (spaceId) => {
    if (user?.workspace?._id === spaceId) {
      return;
    }
    toast.loading('Switching workspace...');
    const { data: res } = await setCurrentWorkspace({ workspaceId: spaceId });

    if (res.data) {
      toast.dismiss();
      setUser({ ...user, workspace: res?.data?.workspace, workspace_member: res?.data?.workspace_member });
      toast.success(`Switched to ${res?.data?.workspace?.name}`);
      // re-load full application
      window.location.reload();
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div
        className={`bg-white transition-all h-screen pb-20 lg:pb-8 pt-8 border border-gray-300 space-y-6 flex flex-col overflow-auto scroll-none md:w-[250px] xl:w-[300px] md:relative fixed top-0 left-0 z-50 ${showSideBar ? 'w-[250px] px-6' : 'w-0 p-0 md:px-6'
        }`}
      >
        <Link to="/">
          {/* <Logo /> */}
          <img src="/logo.svg" className="" width="186px" alt="" />
        </Link>

        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="w-full px-5 py-3 bg-[#173300]/10 rounded-full justify-between items-center inline-flex">
              <div className="justify-start items-center gap-3 flex">
                {
                selectedTeamSpace?.brandLogo ? <img alt="" className="w-[24px] h-[24px] rounded" src={`${Config?.url}${selectedTeamSpace?.brandLogo?.location?.slice(1)}`} /> : (
                  <div className="size-6 bg-[#f1eaba] rounded-lg justify-center items-center flex">
                    <span className="text-[#173300] font-bold text-xs font-inter uppercase">
                      {selectedTeamSpace?.name?.slice(0, 2)}
                    </span>
                  </div>
                )
              }
                <h4 className="text-[#18212f] text-base font-semibold font-inter leading-normal">
                  {selectedTeamSpace?.name}
                </h4>
              </div>
              <svg width="24" height="25" className="size-6" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="chevron-down">
                  <path id="Icon" d="M6 9.2334L12 15.2334L18 9.2334" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="min-w-[200px]">
            <DropdownMenuLabel>Select Workspace</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {teamSpaces?.map((space) => (
              <DropdownMenuItem key={space._id} onClick={() => handleTeamSwitch(space?._id)} className="flex items-center justify-between gap-2.5 px-8 py-2.5">
                <div className="flex items-center gap-1.5">
                  {
                  space?.brandLogo ? <img src={`${Config?.url}${space?.brandLogo?.location.slice(1)}`} className="w-6 h-6 object-cover object-center rounded" alt="" /> : (
                    <div className="size-6 bg-[#f1eaba] rounded-lg justify-center items-center flex">
                      <span className="text-[#173300] font-bold text-xs font-inter uppercase">
                        {space?.name?.slice(0, 2)}
                      </span>
                    </div>
                  )
                }
                  <p className="text-[#344054] text-sm font-medium font-['Inter'] leading-tight">
                    {space?.name}
                    {' '}
                    {space.default && space?.createdBy?.toString() === user?._id?.toString() && '(primary)'}
                  </p>
                </div>
                {space?._id === user?.workspace?._id && <div className="size-2 bg-[#17b169] rounded-full" />}
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem className="hover:bg-transparent p-0 my-1.5">
              <button
                onClick={() => setOpenCreateTeamSpaceModal(true)}
                className="w-full px-[19px] py-[9px] bg-[#f3f3f3] rounded-md justify-center items-center flex gap-1"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="plus-square">
                    <path
                      id="Icon"
                      d="M8 5.33333V10.6667M5.33333 8H10.6667M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.0799 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14Z"
                      stroke="#667085"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>

                <span className="text-[#344054] text-sm font-medium font-inter leading-tight">Create Workspace</span>
              </button>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <div className="flex-grow min-h-[200px] overflow-y-scroll scroll-none">
          <SideNavigation showSideBar={showSideBar} />
        </div>

        {/* My Workspace Details */}
        <UsageProgress
          value={parseFloat(((user?.workspace_member?.hoursUsed || 0) * 60).toFixed(2), 10)}
          max={parseFloat(((user?.workspace_member?.hoursAllocated || 0) * 60).toFixed(2), 10)}
        />
        {/* <div className="text-slate-600 text-sm font-normal leading-tight m-0 border border-gray-200 p-3 rounded-lg">
        <h2 className="text-gray-900 text-lg font-semibold leading-7 mb-4">{user?.workspace?.name}</h2>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Available Seats:
          {user?.workspace?.seats}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Hours Used (Monthly):
          {user?.workspace?.plan !== 'pay_as_you_go'
            ? parseInt(user?.workspace?.hoursUsed, 10)
            : parseInt(user?.workspace?.payAsYouGoHoursUsedMonth, 10)}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight  capitalize">
          Current Plan:
          {user?.workspace?.plan?.replace(/_/g, ' ')}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Last Charged:
          {new Date(user?.workspace?.billingCycleStart).toLocaleDateString()}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Next Billing Date:
          {new Date(Date.parse(user?.workspace?.billingCycleEnd) + 1000 * 60 * 60 * 24 * 30).toLocaleDateString()}
        </div>
      </div> */}
        <div>
          <hr className="mb-2" />
          {/* <LogoutBtn /> */}
          <button
            onClick={handleLogout}
            className="w-full rounded-md justify-center items-center gap-3 flex bg-gray-100 py-3"
          >
            <span className="text-base font-semibold leading-normal">Logout</span>
            <img src="/image/icons/log-out.svg" width={16} height={16} alt="" />
          </button>
        </div>
      </div>
      <CreateTeamSpaceModal open={openCreateTeamSpaceModal} setOpen={setOpenCreateTeamSpaceModal} />
    </>
  );
}

export default Sidebar;
