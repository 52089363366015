import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { setGlobal } from 'reactn';
import initIO from './actions/initIO';
import setAuthToken from './actions/setAuthToken';
import Config from './config';
import store from './store';
import { getCookie } from './lib/utils';

const init = async () => {
  document.addEventListener('gesturestart', (e) => {
    e.preventDefault();
  });

  if (localStorage.getItem('app') !== 'Say Briefly') {
    localStorage.clear();
    localStorage.setItem('app', 'Say Briefly');
  }

  const cookieToken = getCookie('jwt_token');

  if (cookieToken && cookieToken !== 'null' && cookieToken !== '') {
    // console.log('cookieToken', cookieToken);
    localStorage.setItem('token', cookieToken);
    localStorage.setItem('user', JSON.stringify(jwtDecode(cookieToken)));
  } else if (cookieToken === 'null' || cookieToken === '') {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
  let token = localStorage.getItem('token');
  let userString = localStorage.getItem('user');
  let user = userString ? JSON.parse(userString) : null;

  if (token) {
    const decoded = jwtDecode(token, { complete: true });
    const dateNow = new Date();
    const isExpired = decoded.exp * 1000 < dateNow.getTime();

    let result;

    if (!isExpired) {
      try {
        const res = await axios({
          method: 'get',
          url: `${Config.url || ''}/api/v1/user/me`,
          headers: { Authorization: `Bearer ${token}` },
        });
        result = res.data;
      } catch (e) {
        result = null;
      }
    }

    if (!result || result.error) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      token = localStorage.getItem('token');
      userString = localStorage.getItem('user');
      user = userString ? JSON.parse(userString) : null;
    } else {
      user = result.data;
      document.cookie = `jwt_token=${token}; path=/; domain=.saybriefly.com; secure; SameSite=None;`;
    }
  }

  if (token) {
    setAuthToken(token);
    store.dispatch(initIO(token));
  }

  const state = {
    version: '0.0.1',
    entryPath: window.location.pathname,
    token,
    user: user || (token ? jwtDecode(token) : {}),
    rooms: [],
    searchResults: [],
    favorites: [],
    meetings: [],
    nav: 'rooms',
    search: '',
    over: null,
    isPicker: false,
    messages: [],
    streams: [],
    inCall: false,
    video: true,
    audio: true,
    audioStream: null,
    videoStream: null,
    screenStream: null,
    callStatus: null,
    counterpart: null,
    callDirection: null,
    meeting: null,
    showPanel: true,
    panel: 'standard',
    newGroupUsers: [],
    currentRoomId: '',
    refreshNotifications: false,
    refreshMeetings: false,
    refreshProjects: false,
    teamSpaces: [],
  };

  setGlobal(state);
  // .then(() => console.log('Global state init complete!', state));
};

export default init;
