/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
import axios from 'axios';
import {
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getWorkspaces from '../../../../actions/getWorkspaces';
import { Button } from '../../../../components/ui/button';
import { Calendar } from '../../../../components/ui/calendar';
import { Checkbox } from '../../../../components/ui/checkbox';
import {
  DropdownMenu, DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger,
} from '../../../../components/ui/dropdown-menu';
import { Label } from '../../../../components/ui/label';
import {
  Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,
} from '../../../../components/ui/select';
import { Switch } from '../../../../components/ui/switch';
import Config from '../../../../config';
import {
  cn, convertTimeStringToMinutes, convertTo24Hour, generateTimeArray,
} from '../../../../lib/utils';
import TimezoneSelector from '../../components/timezone-slector';
import PreviewSelector from './components/preview-selector';
import URLEmbed from './components/url-embed';

function generateTimeIntervals() {
  const intervals = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hour12 = (hour % 12) || 12; // Convert to 12-hour format
      const hourStr = String(hour12).padStart(2, '0');
      const minuteStr = String(minute).padStart(2, '0');
      const period = hour < 12 ? 'am' : 'pm';
      intervals.push(`${hourStr}:${minuteStr} ${period}`);
    }
  }
  return intervals;
}

const initialDaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => ({
  day,
  isActive: false,
  startTime: '',
  endTime: '',
}));

export const isPastDate = (date) => {
  const today = new Date();
  const selectedDate = new Date(date);
  today.setHours(0, 0, 0, 0);
  selectedDate.setHours(0, 0, 0, 0);
  return selectedDate < today;
};

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const timeFormat = ['12h', '24h'];

function formatTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return hours > 0
    ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`
    : `${minutes} min${minutes > 1 ? 's' : ''}`;
}

function Scheduling() {
  const [date, setDate] = useState(new Date());
  const [daysOfWeek, setDaysOfWeek] = useState(initialDaysOfWeek);
  const [copyDaysOfWeekIndex, setCopyDaysOfWeekIndex] = useState([]);
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormat[0]);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const user = useGlobal('user')[0];
  const [slot, setSlot] = useState(0);
  const [shortDescription, setShortDescription] = useState('');
  const [mode, setMode] = useState('desktop');
  const [teamSpaces, setTeamSpaces] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { code } = Object.fromEntries(searchParams.entries());

  const [calendars, setCalendars] = useState([]);

  const timeIntervals = useMemo(() => generateTimeIntervals(), []);

  const selectedTeamSpace = teamSpaces?.find((space) => space?._id === user?.workspace?._id);

  const getTeamSpaceData = useCallback(async () => {
    try {
      const { data: res } = await getWorkspaces();
      setTeamSpaces(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const verifyGoogleCalenderCode = async () => {
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/verify_calendar_integration?code=${code}`);
      if (searchParams.has('code')) {
        searchParams.delete('code');
        searchParams.delete('scope');
        setSearchParams(searchParams);
      }
      if (res.success) {
        toast.success('Google calendar integrated successfully. ', {
          position: 'top-right',
        });
      } else {
        toast.error('Something went wrong', {
          position: 'top-right',
        });
      }
    } catch (error) {
      toast.error((error.response.data.message || 'Something went wrong'), {
        position: 'top-right',
      });
      console.log(error.response.data.message);
    }
  };
  const getCalendars = async () => {
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_calendars`);
      console.log(res.data);
      setCalendars(res.data);
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-right',
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (code) {
      verifyGoogleCalenderCode();
    }
  }, [code]);

  useEffect(() => {
    getCalendars();
  }, []);

  useEffect(() => {
    getTeamSpaceData();
  }, [getTeamSpaceData]);

  const handleConnectGoogleCalendar = async () => {
    // if (user?.calendar_refresh_token) {
    //   toast.warning('Google calendar already connected.', {
    //     position: 'top-right',
    //   });
    //   return;
    // }
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/request_calendar_integration`);
      window.open(res.data.url, '_self');
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSchedule = useCallback(async () => {
    try {
      const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_schedule`);
      const schedule = data?.data?.schedule;
      if (schedule) {
        const timeSlot = convertTimeStringToMinutes(schedule.timeSlot);
        const updatedDaysOfWeek = initialDaysOfWeek.map((item) => {
          const weekday = schedule.availableWeekdays.find((w) => w.day === item.day);
          return weekday || item;
        });

        setSlot(timeSlot);
        setDaysOfWeek(updatedDaysOfWeek);
        setSelectedTimezone(schedule?.timeZone);
        setShortDescription(schedule?.shortDescription);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule]);

  const handleUpdateSchedule = useCallback(async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${Config.url || ''}/api/v1/user/back_office/save_schedule`,
        {
          availableWeekdays: daysOfWeek,
          timeZone: selectedTimezone.split(' ')[0],
          timeSlot: formatTime(slot),
          shortDescription,
        },
      );
      toast.success('Schedule updated successfully', {
        position: 'top-center',
        description: 'Schedule updated successfully',
      });
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong', {
        position: 'top-center',
        description: 'Something went wrong',
      });
    } finally {
      setIsLoading(false);
    }
  }, [daysOfWeek, selectedTimezone, slot, shortDescription]);

  const handleCopyDaysOfWeek = useCallback((selectedDay) => {
    const updatedDaysOfWeek = daysOfWeek.map((day, index) => (
      copyDaysOfWeekIndex.includes(index)
        ? { ...selectedDay, day: day.day }
        : day
    ));

    setDaysOfWeek(updatedDaysOfWeek);
    handleUpdateSchedule();
  }, [copyDaysOfWeekIndex, daysOfWeek, handleUpdateSchedule]);

  const updateDayOfWeek = useCallback((index, updates) => {
    setDaysOfWeek((prev) => prev.map((day, i) => (i === index ? { ...day, ...updates } : day)));
  }, []);

  const handleDescriptionChange = useCallback((e) => {
    setShortDescription(e.target.value);
  }, []);

  return (
    <div className="space-y-6 py-6">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-[48px]">
        <div className="space-y-6">
          <div className="flex gap-4 flex-wrap">
            {
              calendars.map((calendar) => (
                <Button key={calendar._id} disabled variant="outline" className="shadow-sm cursor-help flex gap-1.5">
                  <img src={`/image/icons/${calendar.provider}-calendar.svg`} className="size-4" alt="" />
                  {calendar.calendarEmail}
                </Button>
              ))
            }
            <Button variant="outline" onClick={handleConnectGoogleCalendar} className="shadow-sm flex gap-1.5">
              <img src="/image/icons/google-calendar.svg" className="size-4" alt="" />
              Connect New Calendar
            </Button>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">Availability</p>
            <div className="bg-[#f2f4f7] p-6 rounded-2xl space-y-6">
              {
                daysOfWeek.map((item, i) => (
                  <div key={i} className="flex flex-col lg:flex-row lg:items-center justify-between gap-6">
                    {/*  */}
                    <div className="flex items-center space-x-2">
                      <Switch
                        id={item.day}
                        checked={item.isActive}
                        onCheckedChange={(val) => updateDayOfWeek(i, { isActive: val })}
                      />
                      <Label htmlFor={item.day} className="cursor-pointer">{item.day}</Label>
                    </div>

                    {item.isActive && (
                      <div className="flex items-center gap-4">
                        <Select
                          value={item.startTime}
                          onValueChange={(val) => updateDayOfWeek(i, { startTime: val })}
                        >
                          <SelectTrigger className="w-[100px] px-2 bg-gray-900 text-white border-none focus:border-none focus:ring-0 focus:shadow-none">
                            <SelectValue placeholder="Select Time" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {timeIntervals.map((item) => <SelectItem key={item} value={convertTo24Hour(item)}>{item}</SelectItem>)}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <span className="text-black text-base font-normal leading-normal">-</span>
                        <Select
                          value={item.endTime}
                          onValueChange={(val) => updateDayOfWeek(i, { endTime: val })}
                        >
                          <SelectTrigger className="w-[100px] px-2 bg-gray-900 text-white border-none focus:border-none focus:ring-0 focus:shadow-none">
                            <SelectValue placeholder="Select Time" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {timeIntervals.map((item) => <SelectItem key={item} value={convertTo24Hour(item)}>{item}</SelectItem>)}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <span className="text-black text-base font-normal leading-normal">+</span>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <button>
                              <img src="/image/icons/copy.svg" alt="" />
                            </button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56 p-3 pt-1">
                            <DropdownMenuLabel className="uppercase text-center">Copy times to</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {
                              daysOfWeek.map((d, i) => (
                                <label key={i} htmlFor={d.day + i} className="cursor-pointer py-1 flex items-center justify-between w-full">
                                  <span>{d.day}</span>
                                  {
                                    item.day === d.day
                                      ? <Checkbox checked disabled className="rounded overflow-hidden" />
                                      : (
                                        <Checkbox
                                          id={d.day + i}
                                          onCheckedChange={() => {
                                            setCopyDaysOfWeekIndex((prev) => {
                                              // Use Set to handle duplicates more easily
                                              const newSet = new Set(prev);
                                              if (!newSet.has(i)) {
                                                newSet.add(i);
                                              }
                                              return Array.from(newSet);
                                            });
                                          }}
                                          className="rounded overflow-hidden"
                                        />
                                      )
                                  }
                                </label>
                              ))
                            }
                            <DropdownMenuItem className="p-0 hover:bg-transparent">
                              <Button className="w-full mt-3" onClick={() => handleCopyDaysOfWeek(item)}>
                                Apply
                              </Button>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                  </div>
                ))
              }
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">Slot</p>
            <div className="flex justify-start">
              <div className="bg-[#f2f4f7] p-6 rounded-2xl">
                <div className="flex items-center gap-7">
                  <div className="flex items-center gap-2">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="clock">
                        <path id="Icon" d="M12.5 6.24609V12.2461L16.5 14.2461M22.5 12.2461C22.5 17.7689 18.0228 22.2461 12.5 22.2461C6.97715 22.2461 2.5 17.7689 2.5 12.2461C2.5 6.72325 6.97715 2.24609 12.5 2.24609C18.0228 2.24609 22.5 6.72325 22.5 12.2461Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                    <p className="text-black text-base font-medium leading-normal">{formatTime(slot)}</p>
                  </div>

                  <div className="flex gap-2.5">
                    <Button onClick={() => setSlot((prev) => prev += 15)} className="bg-white hover:bg-white shadow border border-[#d0d5dd]">
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="plus">
                          <path id="Icon" d="M10.4998 4.41211V16.0788M4.6665 10.2454H16.3332" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                      </svg>
                    </Button>
                    <Button onClick={() => setSlot((prev) => (prev <= 0 ? prev : prev -= 15))} className="bg-white hover:bg-white shadow border border-[#d0d5dd]">
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="minus">
                          <path id="Solid" fillRule="evenodd" clipRule="evenodd" d="M3.8335 10.2454C3.8335 9.78521 4.20659 9.41211 4.66683 9.41211H16.3335C16.7937 9.41211 17.1668 9.78521 17.1668 10.2454C17.1668 10.7057 16.7937 11.0788 16.3335 11.0788H4.66683C4.20659 11.0788 3.8335 10.7057 3.8335 10.2454Z" fill="black" />
                        </g>
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">
              Short Description
            </p>
            <div className="bg-white rounded-lg shadow border border-[#d0d5dd] justify-between items-center inline-flex">
              <textarea
                className="p-3 bg-transparent grow shrink basis-0 text-black text-sm font-normal font-inter leading-tight focus:outline-none w-full"
                cols={50}
                onChange={handleDescriptionChange}
                value={shortDescription}
                placeholder="Short description ensure no crucial information is missed in diverse, multilingual meetings."
              />
            </div>
          </div>

          <div className="space-y-4 flex flex-col items-start">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">Timezone</p>
            <div className="px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#d0d5dd] text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
              <img className="w-4 h-4" src="/image/icons/world.svg" alt="" />
              <TimezoneSelector setSelectedTimezone={setSelectedTimezone} />
            </div>
          </div>

          <Button onClick={handleUpdateSchedule}>
            {isLoading ? (
              <>
                <span className="animate-spin inline-block w-4 h-4 mr-2 border-2 border-white border-r-transparent rounded-full" />
                <span>Updating...</span>
              </>
            ) : 'Update Schedule'}
          </Button>
        </div>
        <div>
          <URLEmbed />
        </div>
      </div>
      <div>
        <PreviewSelector mode={mode} setMode={setMode} />
      </div>
      <div className="bg-[#EEEEEE] rounded-2xl p-6">
        <div className={cn('flex gap-6 mx-auto', mode === 'desktop' ? 'w-full md:flex-row flex-col' : 'lg:w-[480px] w-full flex-col h-[500px] bg-white py-6 rounded-xl overflow-y-auto overflow-x-hidden px-4')}>
          <div className="space-y-4">
            <div className="justify-start items-center gap-3 flex mb-6">
              {
                selectedTeamSpace?.brandLogo && <img alt="" className="w-[24px] h-[24px] rounded" src={`${Config?.url}${selectedTeamSpace?.brandLogo?.location.slice(1)}`} />
              }
              <h4 className="text-[#18212f] text-base font-semibold font-inter leading-normal">
                {selectedTeamSpace?.name}
              </h4>
            </div>
            <div className="flex items-center gap-2">
              <img className="w-6 h-6" src="/image/icons/avatar.svg" alt="" />
              <h4 className="text-black text-sm font-semibold leading-tight m-0">
                {user?.firstName}
                {' '}
                {user?.lastName}
              </h4>
            </div>
            <div className="text-black text-sm font-normal font-['Inter'] leading-tight">
              {shortDescription}
            </div>
            <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
              <img className="w-4 h-4" src="/image/icons/clock.svg" alt="" />
              {formatTime(slot)}
            </p>
            <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
              <img className="w-4 h-4" src="/image/icons/video.svg" alt="" />
              Saybriefly Video
            </p>
            <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
              <img className="w-4 h-4" src="/image/icons/world.svg" alt="" />
              <TimezoneSelector />
            </p>
          </div>
          {/* <hr className="border-gray-500" /> */}
          <div className="lg:min-w-max">
            <Calendar
              mode="single"
              selected={date}
              onSelect={(d) => {
                if (!isPastDate(d)) {
                  setDate(d);
                } else {
                  toast.error('You can not select a previous date.', {
                    position: 'top-center',
                  });
                }
              }}
              classNames={{
                head_cell: 'lg:w-16 w-10',
                cell: 'lg:size-16 size-10',
              }}
              className="flex justify-center w-full"
            />
          </div>
          {/* <hr className="border-gray-500" /> */}

          <div className="space-y-6 flex-grow">
            <div className="flex items-center justify-center gap-6">
              {/*  */}
              <p className="flex gap-[12px] items-center m-0">
                <span className="text-neutral-700 text-base font-semibold leading-normal">
                  {days[new Date(date).getDay()]}
                </span>
                <span className="text-zinc-500 text-sm font-medium leading-tight">
                  {new Date(date).getDate()}
                </span>
              </p>
              <div className="px-1 py-.5 rounded-md border border-neutral-400 justify-end items-center gap-[13.66px] inline-flex">
                {
                  timeFormat.map((item, i) => (
                    <div key={i} onClick={() => setSelectedTimeFormat(item)} className={`px-3 py-[4.25px] cursor-pointer ${selectedTimeFormat === item ? 'bg-neutral-700 rounded text-center text-zinc-300 text-sm font-medium leading-[17.50px]' : ''}`}>
                      {item}
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 max-h-[400px]  overflow-auto">
              {
                (daysOfWeek[new Date(date).getDay()]?.startTime
                  && daysOfWeek[new Date(date).getDay()]?.endTime)
                && generateTimeArray(
                  daysOfWeek[new Date(date).getDay()]?.startTime,
                  daysOfWeek[new Date(date).getDay()]?.endTime,
                  selectedTimeFormat,
                  slot,
                )
                  ?.map((item, i) => (
                    <button key={i} className="px-3 py-2 bg-white rounded-lg border border-gray-300 text-slate-700 text-sm font-semibold leading-tight">
                      {item}
                    </button>
                  ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scheduling;
