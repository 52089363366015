import { useGlobal } from 'reactn';
import TimezoneSelector from '../../../components/timezone-slector';

function formatTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
  }
  return `${minutes} min${minutes > 1 ? 's' : ''}`;
}

function MeetingInfo({ setSelectedTimezone, slot }) {
  const user = useGlobal('user')[0];
  return (
    <div className="space-y-4 lg:pr-6 lg:border-r-2 border-neutral-300">
      <img className="w-6 h-6" src="/image/icons/avatar.svg" alt="" />
      <h4 className="text-black text-sm font-semibold leading-tight m-0">
        {user?.firstName}
        {' '}
        {user?.lastName}
      </h4>
      <h3 className="text-black text-xl font-semibold leading-7 m-0">Secret Meeting</h3>
      <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
        <img className="w-4 h-4" src="/image/icons/clock.svg" alt="" />
        {formatTime(slot)}
      </p>
      <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
        <img className="w-4 h-4" src="/image/icons/video.svg" alt="" />
        SayBriefly Video
      </p>
      <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
        <img className="w-4 h-4" src="/image/icons/world.svg" alt="" />
        <TimezoneSelector setSelectedTimezone={setSelectedTimezone} />
      </p>
    </div>
  );
}

export default MeetingInfo;
