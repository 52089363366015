/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
import { useState } from 'react';
import { toast } from 'sonner';
import createWorkspace from '../../../actions/createWorkspace';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';

function CreateTeamSpaceModal({ open, setOpen }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCreateSpace = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!name) return;
      const data = { name, description: 'My new TeamSpace' };
      const { data: res } = await createWorkspace(data);
      if (res.success) {
        setOpen(false);
        setName('');
        toast.success('TeamSpace created successfully', {
          position: 'top-center',
        });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create Workspace</DialogTitle>
          <DialogDescription className="hidden" />
        </DialogHeader>
        <form onSubmit={handleCreateSpace} className="space-y-4">
          <div className="py-[22px] px-4 bg-[#eeeeee] rounded-2xl flex flex-col gap-1.5">
            <div className="text-[#344054] text-sm font-medium">Workspace Name</div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Name"
              className="px-3.5 py-2.5 bg-white rounded-lg shadow text-[#667085] text-base font-normal"
            />
          </div>
          <div>
            <Button type="submit">
              Create{' '}
              {loading && (
                <span className="size-3 border-2 border-t-transparent border-[#ffed74] rounded-full animate-spin ml-1.5" />
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateTeamSpaceModal;
