/* eslint-disable no-mixed-operators */
/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-unresolved */
import { useGlobal } from 'reactn';
import AvatarGroup from '../../../components/ui/avatar-group';
import Config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';

export function getHourMinuteDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffMilliseconds = Math.abs(date2 - date1);
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes} min.`;
}

function MeetingHistoryItem({
  data,
  selectedMeeting = null,
  setSelectedMeeting = () => {},
  maxAvatar = 2,
  borderColor = '#ffed74',
}) {
  const users =
    typeof data?.users[0] !== 'string'
      ? data?.users?.map((user) => ({
          src: user.picture
            ? `${Config.url || ''}/${user.picture.location}`
            : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email),
          fallback: user.firstName?.charAt(0) + user.lastName?.charAt(0),
          alt: '',
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        }))
      : [getGravatarUrl(`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`, data?.createdBy?.email)];

  const max = typeof data?.users[0] !== 'string' ? maxAvatar : 1;

  const currentUser = useGlobal('user')[0];
  return (
    <div
      onClick={() => setSelectedMeeting(data)}
      className="flex flex-col lg:flex-row py-4 px-6 lg:items-center justify-between gap-2.5 rounded-xl border bg-white cursor-pointer relative"
      style={{
        borderColor: selectedMeeting?._id === data?._id ? borderColor : 'transparent',
      }}
    >
      <div className="flex flex-col lg:flex-row lg:items-center gap-3">
        <div className={`${max === 1 ? 'w-[45px]' : 'w-[65px]'}`}>
          <AvatarGroup avatars={users} borderColor={borderColor} max={max} />
        </div>
        <div className="flex gap-3 items-end">
          {users.slice(0, 2).map((user, index) => (
            <div key={user?.email} className="flex items-end">
              <div className="flex flex-col">
                <p
                  className="text-[#101828] text-sm font-medium leading-tight whitespace-nowrap capitalize"
                  id={`user-name-${user?.email}`}
                >
                  {user?.name}
                </p>
                <p
                  className="text-[#475467] text-xs font-normal font-['Inter'] leading-[18px] truncate"
                  style={{ maxWidth: document.getElementById(`user-name-${user?._id}`)?.offsetWidth || '100px' }}
                >
                  {user?.email}
                </p>
              </div>
              {index !== users.length - 1 && <span>,</span>}
            </div>
          ))}
          {users.length > 2 && (
            <p className="text-[#344054] text-xs font-medium font-['Inter'] leading-[18px]">
              <span>
                {users.length - 2} {users.length - 2 === 1 ? 'other' : 'others'}
              </span>
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2.5">
        {data?.status === 'not_started' &&
          (data?.host_allowed?.length === 0 ||
            (data?.users?.length === 1 && data?.users[0].email === data?.createdBy[0]?.email)) &&
          ((data?.isUpcoming !== true && new Date()?.getTime() - new Date(data?.createdAt)?.getTime() > 100000) ||
            (data?.isUpcoming === true && new Date()?.getTime() - new Date(data?.startedAt)?.getTime() > 100000)) && (
            <span className="text-xs text-[#9F0404] bg-[#FECDCA] px-2 py-0.5 rounded">Abort</span>
          )}

        {currentUser?.workspace?._id !== data?.workspace &&
          currentUser?._id !== data?.createdBy?._id &&
          currentUser?.workspace_member?.role === 'owner' &&
          currentUser?.workspace?.default && (
            <div className="">
              <div className="bg-gray-300 text-black text-[8px] font-medium font-['Inter'] rounded-full py-1 w-10 text-center">
                <span>Guest</span>
              </div>
            </div>
          )}
        <div className="flex lg:flex-col gap-2 font-inter">
          <h4 className="text-[#475467] text-sm font-normal leading-tight">
            {new Date(data?.startedAt).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })}
          </h4>
          <h4 className="text-[#475467] text-sm font-normal leading-tight">
            {data?.callTime ? `${parseFloat(data?.callTime * 60, 10)?.toFixed(2)} min` : '0 min'}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default MeetingHistoryItem;
