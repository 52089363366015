import { registerLicense } from '@syncfusion/ej2-base';
import {
  Day, Inject, Month, ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week, WorkWeek,
} from '@syncfusion/ej2-react-schedule';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import getMeetingById from '../../../actions/getMeetingById';
import getUpcomingMeetings from '../../../actions/getUpcomingMeetings';
import ViewMeetingDetailsModal from './view-meeting-details-modal';

function generateStartEnd(bookingDate, bookingTime, bookingTimeSlot, timezone) {
  // Create date in the specified timezone
  const startDateTime = new Date(
    new Date(bookingDate).toLocaleString('en-US', {
      timeZone: timezone,
    }),
  );

  // Set the time components
  const [hours, minutes] = bookingTime.split(':').map(Number);
  startDateTime.setHours(hours, minutes, 0, 0);

  // Create end date
  const endDateTime = new Date(startDateTime);
  endDateTime.setMinutes(startDateTime.getMinutes() + parseInt(bookingTimeSlot, 10));

  // Format dates to string while preserving timezone
  const formatDateTime = (date) => {
    return date.toLocaleString('sv-SE', { // sv-SE locale gives us YYYY-MM-DD HH:mm format
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).replace(',', '');
  };

  return {
    start: formatDateTime(startDateTime),
    end: formatDateTime(endDateTime),
  };
}

registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF1cX2hIfEx3Qnxbf1x0ZFJMY19bQH9PMyBoS35RckRhWH9ed3dWQmlUVEV2');

export default function MeetingCalendarWrapper({ selectedWorkspace }) {
  const [meetingData, setMeetingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const refreshMeetings = useGlobal('refreshMeetings')[0];
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchUpcomingMeetings = async () => {
      setIsLoading(true);
      try {
        // Fetch data using getUpcomingMeetings
        const { data } = await getUpcomingMeetings(1, 10, `${selectedWorkspace ? `workspaceId=${selectedWorkspace}` : ''}`); // Adjust arguments as needed

        // Map the API response to the required format for ScheduleX
        const formattedMeetings = data.data.map((meeting) => {
          const { start, end } = generateStartEnd(
            meeting.booking.bookingDate,
            meeting.booking.bookingTime,
            meeting.booking.bookingTimeSlot,
            meeting.booking.bookingTimeZone,
          );

          return {
            Id: meeting._id,
            Subject: meeting.notes || 'Untitled Meeting',
            Description: meeting.notes || 'Untitled Meeting',
            StartTime: start,
            EndTime: end,
            Location: 'Say Briefly Meeting',
            CategoryColor: '#173300', // Custom background color
            TextColor: '  #FFED74',
          };
        });
        setMeetingData(formattedMeetings);
      } catch (err) {
        console.error('Error fetching upcoming meetings:', err);
        setMeetingData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUpcomingMeetings();
  }, [refreshMeetings, selectedWorkspace]);

  const onActionComplete = async (args) => {
    console.log(args);
  };

  const onPopupOpen = async (args) => {
    args.cancel = true;
    const meetingId = args.data.Id;
    const { data: result } = await getMeetingById(meetingId);
    setSelectedMeeting(result.data);
    setShowDetails(true);
    if (args.type === 'QuickInfo') {
      // Locate and hide the edit icon
      const editButton = args.element.querySelector('.e-event-edit');
      if (editButton) {
        editButton.style.display = 'none'; // Hide the edit icon
      }
    }
  };

  // Handler to customize event styles
  const onEventRendered = (args) => {
    if (args.data.CategoryColor) {
      args.element.style.backgroundColor = args.data.CategoryColor; // Set background color
      args.element.style.borderRadius = '8px'; // Set background color
      args.element.style.padding = '0px 4px'; // Set background color
    }
    if (args.data.TextColor) {
      args.element.style.color = args.data.TextColor; // Set text color
    }
  };

  if (isLoading) return null;
  return (
    <div>
      <ScheduleComponent
        height="80vh"
        actionComplete={onActionComplete}
        selectedDate={new Date()}
        eventSettings={{ dataSource: meetingData, allowAdding: false }}
        currentView="Day"
        eventRendered={onEventRendered}
        popupOpen={onPopupOpen}
        style={{
          borderRadius: '12px',
        }}
      >
        <ViewsDirective>
          <ViewDirective option="Day" startHour="00:00" endHour="00:00" />
          <ViewDirective option="Week" startHour="00:00" endHour="00:00" />
          <ViewDirective option="Month" showWeekend />
        </ViewsDirective>
        <Inject services={[Day, Week, WorkWeek, Month]} />
      </ScheduleComponent>

      {
        selectedMeeting && (
          <ViewMeetingDetailsModal
            open={showDetails}
            setOpen={setShowDetails}
            meetingData={selectedMeeting}
            meetingId={selectedMeeting?.meeting?.meetingCode}
          />
        )
      }

    </div>
  );
}
