/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import getResources from '../../../actions/getResources';
import AddProjectSpace from '../components/add-project-space';
import ProjectCard from '../components/project-card';
import Layout from '../layout';

function ProjectSpace() {
  const user = useGlobal('user')[0];
  const refreshProjects = useGlobal('refreshProjects')[0];
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const { data } = await getResources();
        // Sort the data to move the default item to the first index
        const sortedData = data.data.sort((a) => (a.default === true ? -1 : 1));
        setResources(sortedData);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [refreshProjects]);
  // console.log(resources);
  return (
    <Layout>
      {/* <BackButton /> */}
      <h4 className="text-[#101828] text-2xl font-semibold font-inter leading-loose mb-6">Project Space</h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        <AddProjectSpace />
        {/* Loading Skeleton Here */}
        {isLoading ? Array(3).fill('').map((_, i) => (
          <div className="animate-pulse flex w-full text-gray-200" key={i}>
            {/*  */}
            <svg className="w-full" width="268" height="208" viewBox="0 0 268 208" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2513_6)">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.70975e-06 43.7825C-0.000197339 38.3249 -0.000375956 33.6163 0.372423 29.7383C0.765908 25.6452 1.63467 21.5741 3.98325 17.6566C7.48698 11.8123 13.0777 7.0607 19.9542 4.08288C24.5635 2.08682 29.3537 1.34846 34.1697 1.01404C38.7326 0.697187 44.2725 0.697347 50.6941 0.697532L99.2411 0.696414C106.472 0.688299 112.83 0.681162 118.677 2.39512C123.798 3.89632 128.462 6.34627 132.332 9.5677C136.751 13.2457 139.587 18.0826 142.812 23.5828L153.709 42.1086H197.849C207.655 42.1085 215.748 42.1084 222.34 42.5662C229.188 43.0417 235.482 44.0621 241.394 46.6224C250.563 50.5928 258.017 56.9283 262.689 64.7207C265.701 69.7455 266.902 75.0951 267.462 80.9147C268 86.5178 268 93.3961 268 101.73V148.14C268 156.474 268 163.352 267.462 168.955C266.902 174.775 265.701 180.125 262.689 185.149C258.017 192.942 250.563 199.277 241.394 203.248C235.482 205.808 229.188 206.828 222.34 207.304C215.748 207.762 207.655 207.762 197.849 207.761H70.1517C60.3457 207.762 52.2523 207.762 45.6596 207.304C38.8122 206.828 32.5178 205.808 26.6056 203.248C17.4369 199.277 9.98262 192.942 5.31098 185.149C2.29852 180.125 1.09783 174.775 0.53838 168.955C-0.000263751 163.352 -0.000140111 156.474 9.70975e-06 148.14V43.7825ZM97.7261 21.4042C107.355 21.4042 109.263 21.5393 110.733 21.9701C112.44 22.4705 113.994 23.2871 115.285 24.3609C116.395 25.2853 117.391 26.6758 121.697 33.9952L126.47 42.1086H24.3645C24.3699 37.2945 24.4072 34.0036 24.6551 31.4245C24.9255 28.6119 25.3845 27.5691 25.6914 27.0572C26.8593 25.1091 28.7229 23.5253 31.0151 22.5327C31.6174 22.2718 32.8443 21.8817 36.1537 21.6519C39.6052 21.4123 44.1402 21.4042 51.1637 21.4042H97.7261Z" fill="currentColor" />
                <g clipPath="url(#clip1_2513_6)">
                  <path d="M127.271 13.0312H11.875V55.6309H127.271V13.0312Z" fill="currentColor" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2513_6">
                  <rect width="268" height="208" fill="white" />
                </clipPath>
                <clipPath id="clip1_2513_6">
                  <rect width="117" height="43" fill="white" transform="translate(11 13)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )) : resources?.map((resource) => (
          <Link to={`/project-space/${resource?._id}`} key={resource?._id}>
            <ProjectCard
              folderColor={resource?.default ? '#FFED74' : '#9ee870'}
              title={resource?.default ? `${user.firstName}'s Default Space` : resource?.name}
              description={resource?.description}
              meetings={resource?.meetings}
              foldersData={[`Call Time: ${resource?.callTime ? `${parseInt(resource?.callTime * 60, 10)} min` : '0 min'}`, `Total Brief: ${resource?.totalBrief}`]}
              users={resource?.default ? [] : resource?.users}
              resource={resource}
            />
          </Link>
        ))}
      </div>
    </Layout>
  );
}

export default ProjectSpace;
