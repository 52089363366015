/* eslint-disable import/no-unresolved */
import moment from 'moment';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { toast } from 'sonner';
import { Calendar } from '../../../../../components/ui/calendar';
import {
  cn,
  convertTo24Hour, generateTimeArray,
  isTimeLater,
} from '../../../../../lib/utils';
import { isPastDate } from '../scheduling';
import MeetingInfo from './meeting-info';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const timeFormat = ['12h', '24h'];

const isSameDate = (date1, date2) => moment(date1).isSame(date2, 'day');

const shouldDisable = (bookings, date, timeStr) => bookings.some((booking) => isSameDate(booking?.bookingDate, date) && (convertTo24Hour(booking?.bookingTime) === convertTo24Hour(timeStr)));

function Scheduler({
  setSelectedTimezone, date, setDate, daysOfWeek, setSelectedTime, selectedTime, bookings, slot,
}) {
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormat[0]);
  const { addToast } = useToasts();

  return (
    <div className="flex lg:flex-row flex-col gap-6 p-6 bg-[#EEEEEE] rounded-xl 2xl:max-w-[1400px] w-full">
      <MeetingInfo setSelectedTimezone={setSelectedTimezone} slot={slot} />
      <hr className="border-gray-500 lg:hidden" />
      <Calendar
        mode="single"
        selected={date}
        onSelect={(d) => {
          if (!isPastDate(d)) {
            setDate(d);
            setSelectedTime('');
          } else {
            toast.error('You can not select a previous date.', {
              position: 'top-center',
            });
          }
        }}
        className="flex justify-center lg:pr-6 px-0 lg:border-r-2 border-neutral-300"
        classNames={{
          head_cell: 'lg:w-16 w-10',
          cell: 'lg:size-16 size-10',
        }}
      />
      <hr className="border-gray-500 lg:hidden" />
      <div className="space-y-6">
        <div className="flex items-center lg:justify-between justify-center gap-6">
          {/*  */}
          <p className="flex gap-[12px] items-center m-0">
            <span className="text-neutral-700 text-base font-semibold leading-normal">
              {date && days[new Date(date).getDay()]}
            </span>
            <span className="text-zinc-500 text-sm font-medium leading-tight">
              {date && new Date(date).getDate()}
            </span>
          </p>
          <div className="px-1 py-.5 rounded-md border border-neutral-400 justify-end items-center gap-[13.66px] inline-flex">
            {
              timeFormat.map((item, i) => (
                <div key={i} onClick={() => setSelectedTimeFormat(item)} className={`px-3 py-[4.25px] cursor-pointer ${selectedTimeFormat === item ? 'bg-neutral-700 rounded text-center text-zinc-300 text-sm font-medium leading-[17.50px]' : ''}`}>
                  {item}
                </div>
              ))
            }
          </div>
        </div>
        <div className="grid grid-cols-2 lg:gap-3 gap-1.5 lg:max-h-[550px] max-h-[200px] overflow-auto p-2">
          {
            (daysOfWeek[new Date(date).getDay()]?.startTime
              || daysOfWeek[new Date(date)?.getDay()]?.endTime)
            && generateTimeArray(
              daysOfWeek[new Date(date).getDay()]?.startTime,
              daysOfWeek[new Date(date)?.getDay()]?.endTime,
              selectedTimeFormat,
              slot,
            )?.map((item, i) => (
              <button
                key={i}
                onClick={() => setSelectedTime((prev) => {
                  if (prev === item) {
                    return '';
                  }
                  if (!isTimeLater(item) && isSameDate(date, new Date())) {
                    addToast('You can not select a past time.', {
                      appearance: 'error',
                      autoDismiss: true,
                    });
                    return prev;
                  }
                  return item;
                })}
                disabled={shouldDisable(bookings, date, item) || (!isTimeLater(item) && isSameDate(date, new Date()))}
                className={cn(
                  `lg:px-12 py-2 rounded-lg border border-gray-300 text-slate-700 text-sm font-semibold leading-tight ${selectedTime === item ? 'bg-neutral-700 text-white' : 'bg-white'}`,
                  shouldDisable(bookings, date, item) || (!isTimeLater(item) && isSameDate(date, new Date())) ? 'hidden' : '',
                )}
              >
                {item}
              </button>
            ))
          }
        </div>

      </div>
    </div>
  );
}

export default Scheduler;
