import axios from 'axios';
import Config from '../config';

const deleteContact = (id) => {
  return axios({
    method: 'delete',
    url: `${Config.url || ''}/api/v1/user/contact/${id}`,
  });
};

export default deleteContact;
