/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import cancelMeeting from '../../../actions/cancelMeeting';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog';
import config from '../../../config';
import Reschedule from './reschedule';

function formatTimestamp(timestamp) {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Define the options for formatting the date
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // Use Intl.DateTimeFormat to format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}

function formatTime24to12(time) {
  // Extract hours and minutes from the time string
  let [hours, minutes] = time.split(':');

  // Convert to number
  hours = parseInt(hours, 10);

  // Determine AM or PM suffix
  const suffix = hours >= 12 ? 'PM' : 'AM';

  // Adjust hours
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Format hours and minutes
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.padStart(2, '0');

  // Return formatted time
  return `${hours}:${minutes} ${suffix}`;
}

function ViewMeetingDetailsModal({ open, setOpen, meetingData, meetingId }) {
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const user = useGlobal('user')[0];
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');

  const toggleAlert = () => {
    setOpen(false);
    setShowCancelAlert(true);
  };
  const handleCancelMeeting = async () => {
    const requestData = {
      bookingId: meetingData.booking._id,
      scheduleId: meetingData.booking.schedule._id,
      cancelledBy: user.email,
    };
    try {
      const { data } = await cancelMeeting(requestData);
      setOpen(false);
      toast.success('Meeting cancelled successfully', {
        position: 'top-center',
      });
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    } finally {
      setRefreshMeetings(!refreshMeetings);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="p-0 bg-transparent border-none" closeButtonClassName="right-2 top-6">
          <DialogHeader>
            <DialogTitle className="hidden" />
            <DialogDescription className="hidden" />
          </DialogHeader>
          <div className="bg-[#EAECF0] p-6 rounded-2xl space-y-5 max-h-[600px] overflow-auto scroll-none">
            <div className="bg-white p-3 py-4 rounded-2xl space-y-6">
              <h4 className="text-gray-900 text-lg font-semibold leading-7">Meeting Details</h4>
              <div className="grid grid-cols-2 gap-x-6">
                <div className="space-y-6">
                  <div className="space-y-[10px]">
                    <p className="text-slate-600 text-sm font-semibold leading-tight m-0">What</p>
                    <p className="text-slate-600 text-sm font-normal leading-tight m-0">
                      {meetingData?.meeting?.notes}
                    </p>
                  </div>
                  <div className="space-y-[10px]">
                    <p className="text-slate-600 text-sm font-semibold leading-tight m-0">When</p>
                    <p className="text-slate-600 text-sm font-normal leading-tight m-0">
                      {meetingData?.booking?.bookingDate ? formatTimestamp(meetingData?.booking?.bookingDate) : ''}

                      {meetingData?.booking?.bookingTime
                        ? ` - ${formatTime24to12(meetingData?.booking?.bookingTime)}`
                        : ''}
                      {/* Monday, April 22, 2024 2:00 PM - 2:30 PM (Western European Summer Time) */}
                    </p>
                  </div>
                </div>
                <div className="space-y-6">
                  <div className="space-y-[10px]">
                    <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Who</p>
                    <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                      {meetingData?.meeting?.createdBy?.firstName} {meetingData?.meeting?.createdBy?.lastName}{' '}
                      <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                        Host
                      </button>
                    </p>
                    <p className="text-slate-600 text-sm font-normal">{meetingData?.meeting?.createdBy?.email}</p>
                  </div>
                  <div className="max-h-[150px] overflow-auto space-y-[10px]">
                    {meetingData?.meeting?.host_allowed
                      .filter((guest) => guest.email !== meetingData?.meeting?.createdBy?.email)
                      .map((guest, i) => (
                        <div className="space-y-[10px]" key={i}>
                          <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0 capitalize">
                            {guest.firstName} {guest.lastName}{' '}
                            <span className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                              Guest
                            </span>
                          </p>
                          <a href={`mailto:${guest.email}`} className="text-slate-600 text-sm font-normal">
                            {guest.email}
                          </a>
                        </div>
                      ))}

                    {meetingData?.meeting?.host_allowed.length === 0 &&
                      meetingData?.booking?.guests?.map((guest, i) => (
                        <div className="space-y-[10px]" key={i}>
                          <p className="text-slate-600 text-sm font-normal leading-tight flex items-center capitalize gap-2.5 m-0">
                            {guest.name}
                            <span className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                              Guest
                            </span>
                          </p>
                          <a href={`mailto:${guest.email}`} className="text-slate-600 text-sm font-normal">
                            {guest.email}
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
                <div />
              </div>
              <div className="space-y-[12px]" onClick={() => setOpen(false)}>
                <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Where</p>
                <Link
                  to={`${config.meetUrl}${meetingId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-slate-600 text-sm font-semibold leading-tight block"
                >
                  {`${config.meetUrl}${meetingId}`}
                </Link>
                <Link to={`${config.meetUrl}${meetingId}`} target="_blank" rel="noreferrer" className="flex text-sm font-semibold leading-tight">
                  <Button className="px-3 py-2 font-semibold rounded-lg shadow">Join Now</Button>
                </Link>
              </div>
            </div>
            {meetingData?.booking?.formSubmission && (
              <div className="bg-white p-3 py-4 rounded-2xl space-y-6">
                <h4 className="text-gray-900 text-lg font-semibold leading-7">QA Response</h4>
                {meetingData?.booking?.formSubmission?.answers?.map((item, i) => (
                  <div key={i} className="space-y-[10px]">
                    <h5 className="text-slate-600 text-sm font-semibold leading-tight m-0">
                      {meetingData?.booking?.formSubmission?.fields[i]?.question}
                    </h5>
                    <p className="text-slate-600 text-sm font-normal leading-tight m-0">{item}</p>
                  </div>
                ))}
              </div>
            )}
            <div className="items-center gap-1 flex justify-center text-slate-600 text-sm font-normal font-inter leading-tight">
              Need to make a change?
              <Reschedule
                userName={user?.username}
                bookingId={meetingData?.booking?._id}
                scheduleId={meetingData?.booking?.schedule?._id}
              >
                <button className="underline">Reschedule</button>
              </Reschedule>
              or
              <button className="underline" onClick={toggleAlert}>
                Cancel
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {showCancelAlert && (
        <AlertDialog open={showCancelAlert} onOpenChange={setShowCancelAlert}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Action</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to cancel this meeting? Once canceled, this action cannot be undone. Press{' '}
                <strong>Continue</strong> to confirm.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleCancelMeeting}>Continue</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}

export default ViewMeetingDetailsModal;
